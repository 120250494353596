import { Header } from "@/components/landing/Header";
import { Features } from "@/components/landing/Features";
import { Pricing } from "@/components/landing/Pricing";
import { TestimonialSlider } from "@/components/landing/TestimonialSlider";
import { HowItWorks } from "@/components/landing/HowItWorks";
import { Footer } from "@/components/landing/Footer";
import { MainNav } from "@/components/MainNav";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { LanguageSelector } from "@/components/LanguageSelector";
import { useTranslation } from "react-i18next";

const Landing = () => {
  const { t } = useTranslation();
  
  const scrollToPricing = () => {
    const pricingSection = document.getElementById('pricing');
    pricingSection?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen relative">
      <div className="border-b bg-background">
        <div className="container flex h-16 items-center px-4">
          <MainNav />
          <div className="ml-auto flex items-center space-x-4">
            <Link to="/login">
              <Button variant="ghost">{t('landing.nav.login')}</Button>
            </Link>
            <Button 
              className="bg-[#e0933c] hover:bg-[#e0933c]/90"
              onClick={scrollToPricing}
            >
              {t('landing.nav.signup')}
            </Button>
          </div>
        </div>
      </div>
      <Header onGetStartedClick={scrollToPricing} />
      <Features />
      <TestimonialSlider />
      <Pricing />
      <HowItWorks />
      <Footer />
      <div className="fixed bottom-4 left-4 z-50">
        <LanguageSelector />
      </div>
    </div>
  );
};

export default Landing;