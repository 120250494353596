import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { FormBusinessProfile, BusinessHoursSchedule } from "./types";
import { useTranslation } from "react-i18next";

interface BusinessFormFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const BusinessFormFields = ({ profile, setProfile }: BusinessFormFieldsProps) => {
  const { t } = useTranslation();

  const handleArrayChange = (field: 'desired_keywords' | 'payment_methods' | 'business_services', value: string) => {
    setProfile({
      ...profile,
      [field]: value ? value.split(',').map(item => item.trim()) : []
    });
  };

  const handleHoursChange = (day: keyof BusinessHoursSchedule, type: 'open' | 'close', value: string) => {
    setProfile({
      ...profile,
      opening_hours_json: {
        ...profile.opening_hours_json,
        [day]: {
          ...profile.opening_hours_json[day],
          [type]: value
        }
      }
    });
  };

  return (
    <div className="grid gap-6 w-full">
      <div className="grid md:grid-cols-2 gap-4">
        <div>
          <Label htmlFor="businessName">{t('business.form.businessName')}</Label>
          <Input
            id="businessName"
            value={profile.business_name}
            onChange={(e) => setProfile({ ...profile, business_name: e.target.value })}
          />
        </div>

        <div>
          <Label htmlFor="businessEmail">{t('business.form.businessEmail')}</Label>
          <Input
            id="businessEmail"
            type="email"
            value={profile.business_email || ''}
            onChange={(e) => setProfile({ ...profile, business_email: e.target.value })}
          />
        </div>

        <div>
          <Label htmlFor="websiteUrl">{t('business.form.websiteUrl')}</Label>
          <Input
            id="websiteUrl"
            value={profile.website_url || ''}
            onChange={(e) => setProfile({ ...profile, website_url: e.target.value })}
          />
        </div>

        <div>
          <Label htmlFor="phoneNumber">{t('business.form.phoneNumber')}</Label>
          <Input
            id="phoneNumber"
            value={profile.phone_number}
            onChange={(e) => setProfile({ ...profile, phone_number: e.target.value })}
          />
        </div>

        <div>
          <Label htmlFor="category">{t('business.form.category')}</Label>
          <Input
            id="category"
            value={profile.category}
            onChange={(e) => setProfile({ ...profile, category: e.target.value })}
          />
        </div>

        <div>
          <Label htmlFor="openingDate">{t('business.form.openingDate')}</Label>
          <Input
            id="openingDate"
            type="date"
            value={profile.opening_date || ''}
            onChange={(e) => setProfile({ ...profile, opening_date: e.target.value })}
          />
        </div>
      </div>

      <div className="space-y-4">
        <Label>{t('business.form.openingHours')}</Label>
        {Object.entries(profile.opening_hours_json).map(([day, hours]) => (
          <div key={day} className="grid grid-cols-3 gap-4 items-center">
            <span className="capitalize">{day}</span>
            <Input
              type="time"
              value={hours.open}
              onChange={(e) => handleHoursChange(day as keyof BusinessHoursSchedule, 'open', e.target.value)}
              placeholder="Opening time"
            />
            <Input
              type="time"
              value={hours.close}
              onChange={(e) => handleHoursChange(day as keyof BusinessHoursSchedule, 'close', e.target.value)}
              placeholder="Closing time"
            />
          </div>
        ))}
      </div>

      <div className="grid md:grid-cols-2 gap-4">
        <div>
          <Label htmlFor="streetAddress">{t('business.form.streetAddress')}</Label>
          <Input
            id="streetAddress"
            value={profile.street_address}
            onChange={(e) => setProfile({ ...profile, street_address: e.target.value })}
          />
        </div>

        <div>
          <Label htmlFor="city">{t('business.form.city')}</Label>
          <Input
            id="city"
            value={profile.city}
            onChange={(e) => setProfile({ ...profile, city: e.target.value })}
          />
        </div>

        <div>
          <Label htmlFor="state">{t('business.form.state')}</Label>
          <Input
            id="state"
            value={profile.state}
            onChange={(e) => setProfile({ ...profile, state: e.target.value })}
          />
        </div>

        <div>
          <Label htmlFor="zipCode">{t('business.form.zipCode')}</Label>
          <Input
            id="zipCode"
            value={profile.zip_code}
            onChange={(e) => setProfile({ ...profile, zip_code: e.target.value })}
          />
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <Label htmlFor="shortDescription">{t('business.form.shortDescription')}</Label>
          <Input
            id="shortDescription"
            value={profile.short_description || ''}
            onChange={(e) => setProfile({ ...profile, short_description: e.target.value })}
            placeholder="Brief description of your business"
          />
        </div>

        <div>
          <Label htmlFor="longDescription">{t('business.form.longDescription')}</Label>
          <Textarea
            id="longDescription"
            value={profile.long_description || ''}
            onChange={(e) => setProfile({ ...profile, long_description: e.target.value })}
            placeholder="Detailed description of your business"
            className="min-h-[150px]"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-4">
        <div>
          <Label htmlFor="facebookUrl">{t('business.form.facebookUrl')}</Label>
          <Input
            id="facebookUrl"
            value={profile.facebook_url || ''}
            onChange={(e) => setProfile({ ...profile, facebook_url: e.target.value })}
            placeholder="https://facebook.com/yourbusiness"
          />
        </div>

        <div>
          <Label htmlFor="instagramUrl">{t('business.form.instagramUrl')}</Label>
          <Input
            id="instagramUrl"
            value={profile.instagram_url || ''}
            onChange={(e) => setProfile({ ...profile, instagram_url: e.target.value })}
            placeholder="https://instagram.com/yourbusiness"
          />
        </div>
      </div>

      <div>
        <Label htmlFor="businessServices">{t('business.form.businessServices')}</Label>
        <Input
          id="businessServices"
          value={profile.business_services?.join(', ') || ''}
          onChange={(e) => handleArrayChange('business_services', e.target.value)}
          placeholder="Enter services separated by commas"
        />
      </div>

      <div>
        <Label htmlFor="seoCampaignGoal">{t('business.form.seoCampaignGoal')}</Label>
        <Textarea
          id="seoCampaignGoal"
          value={profile.seo_campaign_goal}
          onChange={(e) => setProfile({ ...profile, seo_campaign_goal: e.target.value })}
          className="min-h-[100px]"
        />
      </div>

      <div>
        <Label htmlFor="desiredKeywords">{t('business.form.desiredKeywords')}</Label>
        <Input
          id="desiredKeywords"
          value={profile.desired_keywords?.join(', ') || ''}
          onChange={(e) => handleArrayChange('desired_keywords', e.target.value)}
          placeholder={t('business.form.keywordsPlaceholder')}
        />
      </div>

      <div>
        <Label htmlFor="paymentMethods">{t('business.form.paymentMethods')}</Label>
        <Input
          id="paymentMethods"
          value={profile.payment_methods?.join(', ') || ''}
          onChange={(e) => handleArrayChange('payment_methods', e.target.value)}
          placeholder={t('business.form.paymentMethodsPlaceholder')}
        />
      </div>

      <div>
        <Label htmlFor="promotions">{t('business.form.promotions')}</Label>
        <Textarea
          id="promotions"
          value={profile.promotions || ''}
          onChange={(e) => setProfile({ ...profile, promotions: e.target.value })}
          placeholder={t('business.form.promotionsPlaceholder')}
          className="min-h-[100px]"
        />
      </div>
    </div>
  );
};
