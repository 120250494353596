import { useParams, Navigate } from "react-router-dom";
import { SignupFlowSteps } from "@/components/onboarding/SignupFlowSteps";
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

export const ChatSignupPage = () => {
  const { packageType } = useParams<{ packageType: string }>();
  const [step, setStep] = useState(1);
  const [personalInfo, setPersonalInfo] = useState<any>(null);
  const [businessInfo, setBusinessInfo] = useState<any>(null);
  const { toast } = useToast();

  if (!packageType || !["maps_seo", "maps_website_seo"].includes(packageType)) {
    return <Navigate to="/" />;
  }

  const handleCombinedInfoComplete = async (data: any) => {
    try {
      // Create user account
      const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
      });

      if (signUpError) throw signUpError;
      if (!signUpData.user) throw new Error("No user returned from signup");

      // Create profile with retry mechanism
      let profileError;
      let retryCount = 0;
      const maxRetries = 3;

      while (retryCount < maxRetries) {
        const { error } = await supabase
          .from('profiles')
          .insert({
            id: signUpData.user.id,
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
            phone_number: data.phoneNumber || null,
          });

        if (!error) {
          console.log('Profile created successfully');
          break;
        }

        profileError = error;
        retryCount++;
        await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
      }

      if (retryCount === maxRetries) {
        throw profileError || new Error('Failed to create profile after multiple attempts');
      }

      // Create business profile
      const { error: businessError } = await supabase
        .from('business_profiles')
        .insert({
          user_id: signUpData.user.id,
          business_name: data.businessName,
          category: data.businessCategory,
          website_url: data.websiteUrl || null,
          street_address: data.streetAddress,
          city: data.city,
          state: data.state,
          zip_code: data.zipCode,
          phone_number: data.phoneNumber || '',
          opening_hours: '9:00-17:00',
          seo_campaign_goal: 'To be defined',
        });

      if (businessError) throw businessError;

      // Store info for next steps
      setPersonalInfo({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phoneNumber,
      });
      
      setBusinessInfo({
        businessName: data.businessName,
        category: data.businessCategory,
        website: data.websiteUrl,
        address: data.streetAddress,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode,
      });

      // Send data to webhook using GET
      try {
        console.log('Sending data to webhook...');
        const { error: webhookError } = await supabase.functions.invoke('zapier-webhook', {
          body: { // Changed from params to body
            personalInfo: {
              email: data.email,
              first_name: data.firstName,
              last_name: data.lastName,
              phone: data.phoneNumber,
            },
            businessInfo: {
              business_name: data.businessName,
              category: data.businessCategory,
              website_url: data.websiteUrl,
              street_address: data.streetAddress,
              city: data.city,
              state: data.state,
              zip_code: data.zipCode,
            },
            metadata: {
              signup_date: new Date().toISOString(),
              source: 'web',
              package_type: packageType
            }
          }
        });

        if (webhookError) {
          console.error('Error sending data to webhook:', webhookError);
          throw webhookError;
        }
        
        console.log('Successfully sent data to webhook');
      } catch (error) {
        console.error('Error invoking webhook function:', error);
        // We don't throw here to allow the signup process to continue
      }

      setStep(2);
    } catch (error: any) {
      console.error('Error in signup:', error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const handleCheckoutComplete = () => {
    setStep(3);
  };

  const handleGoogleConnectComplete = async (connected: boolean) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) throw new Error("No authenticated user found");

      const { error: sessionError } = await supabase
        .from('onboarding_sessions')
        .insert([{
          user_id: user.id,
          package_type: packageType,
          first_name: personalInfo.firstName,
          last_name: personalInfo.lastName,
          email: personalInfo.email,
          business_name: businessInfo.businessName,
          website_url: businessInfo.website,
          business_category: businessInfo.category,
          street_address: businessInfo.address,
          google_business_connected: connected,
          completed: true,
          checkout_completed: true
        }]);

      if (sessionError) throw sessionError;

      window.location.href = '/dashboard';
    } catch (error: any) {
      console.error('Error completing onboarding:', error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="min-h-screen bg-background flex items-center justify-center p-4">
      <div className="w-full max-w-2xl">
        <SignupFlowSteps
          step={step}
          personalInfo={personalInfo}
          businessInfo={businessInfo}
          onCombinedInfoComplete={handleCombinedInfoComplete}
          onCheckoutComplete={handleCheckoutComplete}
          onGoogleConnectComplete={handleGoogleConnectComplete}
        />
      </div>
    </div>
  );
};

export default ChatSignupPage;
