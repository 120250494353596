import { History } from "lucide-react";
import { Button } from "@/components/ui/button";

interface SearchHistoryProps {
  searchHistory: string[];
  onHistoryItemClick: (query: string) => void;
}

export const SearchHistory = ({ searchHistory, onHistoryItemClick }: SearchHistoryProps) => {
  if (searchHistory.length === 0) return null;

  return (
    <div className="flex items-center gap-2 mb-4 overflow-x-auto">
      <History className="h-4 w-4 text-gray-400 flex-shrink-0" />
      {searchHistory.map((query, index) => (
        <Button
          key={index}
          variant="ghost"
          size="sm"
          onClick={() => onHistoryItemClick(query)}
          className="flex-shrink-0"
        >
          {query}
        </Button>
      ))}
    </div>
  );
};