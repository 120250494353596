import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Shield, Link, Mail } from "lucide-react";
import { Navigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import { useState } from "react";
import { PartnerResources } from "@/components/partner/PartnerResources";
import { PartnerVideos } from "@/components/partner/PartnerVideos";

const Partner = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [contactFormVisible, setContactFormVisible] = useState(false);
  
  const { data: profile, isLoading: profileLoading, error: profileError } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();

      if (error) throw error;
      return data;
    },
  });

  if (profileLoading) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen bg-gray-50">
          <AppSidebar />
          <div className="flex-1 p-8">
            <div className="max-w-6xl mx-auto">
              <div className="animate-pulse">
                <div className="h-8 w-64 bg-gray-200 rounded mb-4"></div>
                <div className="h-4 w-96 bg-gray-200 rounded mb-8"></div>
              </div>
            </div>
          </div>
        </div>
      </SidebarProvider>
    );
  }

  if (profileError) {
    toast({
      title: "Error",
      description: "Failed to load profile information. Please try again later.",
      variant: "destructive",
    });
    return <Navigate to="/dashboard" />;
  }

  if (profile && !profile.is_partner && !profile.is_admin) {
    return <Navigate to="/dashboard" />;
  }

  const handleContactSupport = () => {
    setContactFormVisible(true);
    toast({
      title: "Contact Support",
      description: "Our partner support team will get back to you shortly.",
    });
  };

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-gray-50">
        <AppSidebar />
        <div className="flex-1 p-8">
          <div className="max-w-6xl mx-auto space-y-8">
            <div className="flex items-center justify-between">
              <div>
                <h1 className="text-2xl font-semibold text-gray-900">Partner Portal</h1>
                <p className="text-sm text-gray-500">Access exclusive partner resources and support</p>
              </div>
              <Shield className="h-8 w-8 text-[#F97316]" />
            </div>

            <PartnerResources />
            <PartnerVideos />

            {/* Affiliate Links Section */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
                <Link className="h-5 w-5 text-[#F97316]" />
                Affiliate Links
              </h2>
              <Card className="p-6 bg-gray-50 border-dashed">
                <div className="text-center">
                  <h3 className="font-semibold text-gray-900 mb-2">Coming Soon</h3>
                  <p className="text-sm text-gray-500">
                    Generate and manage your affiliate links to earn commissions.
                    This feature is currently under development.
                  </p>
                </div>
              </Card>
            </section>

            {/* Contact Support Section */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
                <Mail className="h-5 w-5 text-[#F97316]" />
                Partner Support
              </h2>
              <Card className="p-6">
                <p className="text-sm text-gray-500 mb-4">
                  Need help? Our dedicated partner support team is here to assist you.
                </p>
                <Button onClick={handleContactSupport}>
                  Contact Partner Support
                </Button>
              </Card>
            </section>
          </div>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default Partner;