export const landing = {
  hero: {
    title: "Transforma tu Presencia Local con SEO Experto",
    subtitle: "Domina los resultados de búsqueda locales y atrae más clientes a tu negocio con nuestras estrategias SEO basadas en datos.",
    cta: "Comienza tu Viaje",
  },
  features: {
    title: "Soluciones SEO Integrales",
    subtitle: "Todo lo que necesitas para impulsar tu visibilidad local y atraer más clientes a tu negocio.",
    items: {
      localSeo: {
        title: "Optimización SEO Local",
        description: "Domina los resultados de búsqueda locales y atrae clientes cercanos",
      },
      analytics: {
        title: "Análisis de Rendimiento",
        description: "Sigue tu progreso con informes e insights detallados",
      },
      dashboard: {
        title: "Panel de Control",
        description: "Gestiona tu campaña SEO desde una ubicación central",
      },
      multiLanguage: {
        title: "Soporte Multilingüe",
        description: "Soporte completo de plataforma, correo y chat en varios idiomas",
      },
      experience: {
        title: "12 Años de Experiencia",
        description: "Más de una década de experiencia y éxito probado en SEO",
      },
      roi: {
        title: "Enfocado en ROI",
        description: "Estrategias diseñadas para maximizar tu retorno de inversión",
      },
    },
    cta: "Comienza tu Viaje SEO",
  },
  howItWorks: {
    title: "Cómo Funciona",
    subtitle: "Comienza con nuestro proceso simple de tres pasos",
    steps: {
      step1: {
        title: "Selecciona tu Paquete",
        description: "Elige el paquete SEO que mejor se adapte a las necesidades y objetivos de tu negocio",
      },
      step2: {
        title: "Incorporación Rápida",
        description: "Completa nuestro proceso de incorporación optimizado con guía experta",
      },
      step3: {
        title: "Observa Crecer tu Negocio",
        description: "Monitorea tu progreso mientras tu presencia online y base de clientes se expanden",
      },
    },
  },
  testimonials: {
    title: "Historias de Éxito de Clientes",
    subtitle: "Mira cómo hemos ayudado a negocios como el tuyo a lograr un crecimiento notable",
    items: {
      restaurant: {
        content: "El paquete Maps SEO nos ayudó a aumentar significativamente nuestra visibilidad local. ¡Nuestras reservas de restaurante aumentaron un 40%!",
        name: "Sarah Johnson",
        role: "Propietaria de Restaurante",
      },
      retail: {
        content: "Solución SEO integral que entregó resultados reales. Nuestra presencia online nunca ha sido más fuerte.",
        name: "Mike Thompson",
        role: "Gerente de Tienda Minorista",
      },
      service: {
        content: "Gran servicio y resultados increíbles. ¡Nuestro ranking en Google Maps mejoró en semanas!",
        name: "Lisa Chen",
        role: "Propietaria de Negocio de Servicios",
      },
    },
  },
  pricing: {
    title: "Nuestros Planes SEO Locales",
    mapsSeo: {
      title: "SEO para Maps",
      price: "$598",
      description: "Sé descubierto por clientes cercanos con un enfoque preciso en Google Maps.",
      features: [
        "Optimización de Google Maps",
        "Gestión de Citaciones Locales",
        "Optimización en Radio de 5 Millas",
      ],
      bestFor: "Ideal para: Negocios que buscan atraer tráfico local y captar clientes cercanos.",
      cta: "Comenzar",
    },
    mapsWebsiteSeo: {
      title: "SEO Maps + Sitio Web",
      price: "$998",
      description: "Expande tu visibilidad más allá de Maps con una combinación de estrategias SEO locales y web.",
      features: [
        "Todo lo incluido en SEO Maps",
        "Optimización Básica de Sitio Web",
        "SEO Técnico (1 página/mes)",
        "Orientación a 1 Ciudad",
      ],
      bestFor: "Ideal para: Negocios que desean captar tráfico tanto local como de motores de búsqueda.",
      cta: "Comenzar",
    },
    customSeo: {
      title: "SEO Personalizado",
      price: "Precios variables",
      description: "Soluciones personalizadas para negocios en industrias competitivas o con necesidades únicas.",
      features: [
        "SEO para Verticales Competitivas",
        "Orientación Multi-Ciudad",
        "Estrategias totalmente personalizadas",
        "Llamadas Mensuales de Estrategia",
      ],
      bestFor: "Ideal para: Negocios que apuntan a múltiples ubicaciones o requieren estrategias SEO avanzadas.",
      cta: "Contáctanos",
    },
  },
  footer: {
    company: {
      title: "Empresa",
      about: "Sobre Nosotros",
      contact: "Contacto",
      careers: "Carreras",
    },
    resources: {
      title: "Recursos",
      blog: "Blog",
      caseStudies: "Casos de Éxito",
      faqs: "Preguntas Frecuentes",
    },
    legal: {
      title: "Legal",
      privacy: "Política de Privacidad",
      terms: "Términos de Servicio",
      cookies: "Política de Cookies",
    },
    contact: {
      title: "Contacta con Nosotros",
      description: "¿Necesitas ayuda con tu estrategia SEO? Nuestros expertos están aquí para ayudarte.",
      cta: "Contactar Soporte",
    },
    copyright: "© 2024 SEO Client Portal. Todos los derechos reservados.",
  },
  nav: {
    login: "Iniciar Sesión",
    signup: "Registrarse",
    caseStudies: "Casos de Éxito",
    pricing: "Precios",
  },
};