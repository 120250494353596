import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { CreditCard } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";

export const UpdateCard = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      // Load Accept.js
      if (!(window as any).Accept) {
        const script = document.createElement("script");
        script.src = "https://jstest.authorize.net/v1/Accept.js";
        script.async = true;
        document.body.appendChild(script);
        await new Promise((resolve) => script.onload = resolve);
      }

      // Generate payment nonce
      const secureData = {
        cardData: {
          cardNumber,
          month: expiryDate.split('/')[0],
          year: '20' + expiryDate.split('/')[1],
          cardCode: cvv
        }
      };

      const response = await new Promise((resolve, reject) => {
        (window as any).Accept.dispatchData(secureData, (response: any) => {
          if (response.messages.resultCode === 'Error') {
            reject(new Error(response.messages.message[0].text));
          }
          resolve(response);
        });
      });

      // Get current subscription
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");

      const { data: subscription } = await supabase
        .from('subscriptions')
        .select('external_subscription_id')
        .eq('user_id', user.id)
        .single();

      if (!subscription?.external_subscription_id) {
        throw new Error("No active subscription found");
      }

      // Update card with Edge Function
      const { error } = await supabase.functions.invoke('update-card', {
        body: {
          subscriptionId: subscription.external_subscription_id,
          paymentNonce: (response as any).opaqueData.dataValue,
        }
      });

      if (error) throw error;

      toast({
        title: "Card Updated",
        description: "Your card has been successfully updated.",
      });

      queryClient.invalidateQueries({ queryKey: ['subscription'] });
      
      // Reset form
      setCardNumber("");
      setExpiryDate("");
      setCvv("");
    } catch (error: any) {
      console.error('Card update error:', error);
      toast({
        title: "Update Failed",
        description: error.message || "Failed to update card",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const formatCardNumber = (value: string) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  };

  const formatExpiryDate = (value: string) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    if (v.length >= 2) {
      return v.slice(0, 2) + '/' + v.slice(2, 4);
    }
    return v;
  };

  return (
    <Card className="p-6 bg-white shadow-sm border border-gray-100">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-3 rounded-full bg-primary/10">
          <CreditCard className="h-6 w-6 text-primary" />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">Update Payment Method</h2>
          <p className="text-sm text-gray-500">Update your card information</p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="cardNumber">Card Number</Label>
          <Input
            id="cardNumber"
            type="text"
            value={cardNumber}
            onChange={(e) => setCardNumber(formatCardNumber(e.target.value))}
            placeholder="1234 5678 9012 3456"
            maxLength={19}
            required
            disabled={isProcessing}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="expiryDate">Expiry Date</Label>
            <Input
              id="expiryDate"
              type="text"
              value={expiryDate}
              onChange={(e) => setExpiryDate(formatExpiryDate(e.target.value))}
              placeholder="MM/YY"
              maxLength={5}
              required
              disabled={isProcessing}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="cvv">CVV</Label>
            <Input
              id="cvv"
              type="text"
              value={cvv}
              onChange={(e) => setCvv(e.target.value.replace(/\D/g, '').slice(0, 4))}
              placeholder="123"
              maxLength={4}
              required
              disabled={isProcessing}
            />
          </div>
        </div>

        <Button 
          type="submit" 
          className="w-full"
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : "Update Card"}
        </Button>
      </form>
    </Card>
  );
};