import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Button } from "@/components/ui/button";
import {
  Bold,
  Italic,
  List,
  Heading1,
  Heading2,
  Heading3,
  Quote,
  Undo,
  Redo,
  ListOrdered,
} from 'lucide-react';

interface RichTextEditorProps {
  content: string;
  onChange: (content: string) => void;
}

export const RichTextEditor = ({ content, onChange }: RichTextEditorProps) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
    editorProps: {
      attributes: {
        class: 'prose prose-slate max-w-none min-h-[200px] focus:outline-none',
      },
    },
  });

  if (!editor) {
    return null;
  }

  const MenuButton = ({ 
    onClick, 
    icon: Icon, 
    label, 
    isActive = false 
  }: { 
    onClick: () => void; 
    icon: any; 
    label: string; 
    isActive?: boolean;
  }) => (
    <Button
      onClick={onClick}
      variant="ghost"
      size="sm"
      className={`${isActive ? 'bg-muted' : ''}`}
      title={label}
    >
      <Icon className="h-4 w-4" />
    </Button>
  );

  return (
    <div className="border rounded-md">
      <div className="border-b p-2 flex flex-wrap gap-1 bg-muted/50">
        <MenuButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          icon={Bold}
          label="Bold"
          isActive={editor.isActive('bold')}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          icon={Italic}
          label="Italic"
          isActive={editor.isActive('italic')}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          icon={Heading1}
          label="Heading 1"
          isActive={editor.isActive('heading', { level: 1 })}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          icon={Heading2}
          label="Heading 2"
          isActive={editor.isActive('heading', { level: 2 })}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          icon={Heading3}
          label="Heading 3"
          isActive={editor.isActive('heading', { level: 3 })}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          icon={List}
          label="Bullet List"
          isActive={editor.isActive('bulletList')}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          icon={ListOrdered}
          label="Numbered List"
          isActive={editor.isActive('orderedList')}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          icon={Quote}
          label="Quote"
          isActive={editor.isActive('blockquote')}
        />
        <MenuButton
          onClick={() => editor.chain().focus().undo().run()}
          icon={Undo}
          label="Undo"
        />
        <MenuButton
          onClick={() => editor.chain().focus().redo().run()}
          icon={Redo}
          label="Redo"
        />
      </div>
      <EditorContent editor={editor} className="p-4" />
    </div>
  );
};