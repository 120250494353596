import { Card } from "@/components/ui/card";
import { ResourceCard } from "./ResourceCard";
import { Skeleton } from "@/components/ui/skeleton";

interface Article {
  id: string;
  title: string;
  description: string;
  link: string;
  category: string;
  premium: boolean;
  read_time_minutes: number;
  tags: string[];
  article_views?: {
    completed: boolean;
    progress_percent: number;
    time_spent_seconds: number;
  }[];
}

interface ResourceListProps {
  articles: Article[];
  isLoading: boolean;
}

export const ResourceList = ({ articles, isLoading }: ResourceListProps) => {
  if (isLoading) {
    return (
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {[1, 2, 3].map((i) => (
          <Card key={i} className="p-6">
            <Skeleton className="h-4 w-3/4 mb-4" />
            <Skeleton className="h-20 w-full mb-4" />
            <Skeleton className="h-4 w-1/4" />
          </Card>
        ))}
      </div>
    );
  }

  if (!articles.length) {
    return (
      <Card className="p-6 text-center">
        <p className="text-gray-500">No resources available at this time.</p>
      </Card>
    );
  }

  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
      {articles.map((article) => (
        <ResourceCard
          key={article.id}
          article={article}
          progress={article.article_views?.[0]?.progress_percent || 0}
          completed={article.article_views?.[0]?.completed || false}
        />
      ))}
    </div>
  );
};