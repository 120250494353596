import { useState, useRef, useEffect } from 'react';
import { useBusinessSearch } from '@/hooks/useBusinessSearch';
import { Loader2, Building2 } from 'lucide-react';
import { Input } from "@/components/ui/input";
import { BusinessDetails, PlacePrediction } from './types';

interface BusinessSearchInputProps {
  onBusinessSelect: (details: BusinessDetails) => void;
}

export const BusinessSearchInput = ({ onBusinessSelect }: BusinessSearchInputProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const {
    search,
    setSearch,
    predictions,
    isSearching,
    setSelectedPlaceId,
    placeDetails,
    isLoadingDetails
  } = useBusinessSearch();

  // When we get place details, pass them to the parent
  useEffect(() => {
    if (placeDetails && !isLoadingDetails) {
      console.log('Place details received:', placeDetails); // Debug log
      const businessDetails: BusinessDetails = {
        name: placeDetails.name || '',
        website: placeDetails.website || '',
        formatted_address: placeDetails.formatted_address || '',
        formatted_phone_number: placeDetails.formatted_phone_number || '',
        types: placeDetails.types || []
      };
      onBusinessSelect(businessDetails);
      setShowDropdown(false);
    }
  }, [placeDetails, isLoadingDetails, onBusinessSelect]);

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (prediction: PlacePrediction) => {
    console.log('Selected prediction:', prediction); // Debug log
    setSelectedPlaceId(prediction.place_id);
    setSearch(prediction.structured_formatting.main_text);
  };

  return (
    <div className="relative w-full" ref={ref}>
      <div className="relative">
        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
          <Building2 className="h-4 w-4 text-gray-500" />
        </div>
        <Input
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setShowDropdown(!!e.target.value);
          }}
          placeholder="Search for your business..."
          className="pl-9 pr-4"
        />
        {isSearching && (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
            <Loader2 className="h-4 w-4 animate-spin text-gray-500" />
          </div>
        )}
      </div>

      {showDropdown && search.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200">
          <div className="max-h-60 overflow-auto py-1">
            {isSearching ? (
              <div className="px-4 py-2 text-sm text-gray-500">
                Searching...
              </div>
            ) : predictions && predictions.length > 0 ? (
              predictions.map((prediction) => (
                <button
                  key={prediction.place_id}
                  onClick={() => handleSelect(prediction)}
                  className="w-full text-left px-4 py-2 text-sm hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                >
                  <div className="font-medium">
                    {prediction.structured_formatting.main_text}
                  </div>
                  <div className="text-sm text-gray-500">
                    {prediction.structured_formatting.secondary_text}
                  </div>
                </button>
              ))
            ) : (
              <div className="px-4 py-2 text-sm text-gray-500">
                No results found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};