import { Table, TableBody } from "@/components/ui/table";
import { AlertCircle } from "lucide-react";
import { BusinessTableHeader } from "./BusinessTableHeader";
import { BusinessTableRow } from "./BusinessTableRow";
import { BusinessWithProfile, CompletionStatus } from "./types";

interface BusinessTableContentProps {
  businesses: BusinessWithProfile[];
  sortField: keyof BusinessWithProfile;
  sortDirection: 'asc' | 'desc';
  onSort: (field: keyof BusinessWithProfile) => void;
  onDelete: (businessId: string) => void;
  getCompletionStatus: (business: BusinessWithProfile) => CompletionStatus;
}

export const BusinessTableContent = ({
  businesses,
  sortField,
  sortDirection,
  onSort,
  onDelete,
  getCompletionStatus,
}: BusinessTableContentProps) => {
  return (
    <div className="rounded-md border overflow-x-auto">
      <Table>
        <BusinessTableHeader
          sortField={sortField}
          sortDirection={sortDirection}
          onSort={onSort}
        />
        <TableBody>
          {businesses.length === 0 ? (
            <tr>
              <td colSpan={7} className="text-center py-8">
                <div className="flex flex-col items-center gap-2">
                  <AlertCircle className="h-8 w-8 text-gray-400" />
                  <p>No businesses found</p>
                </div>
              </td>
            </tr>
          ) : (
            businesses.map((business) => (
              <BusinessTableRow
                key={business.id}
                business={business}
                getCompletionStatus={getCompletionStatus}
                onDelete={() => onDelete(business.id)}
              />
            ))
          )}
        </TableBody>
      </Table>
    </div>
  );
};