import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { BusinessSearchInput } from "../business-search/BusinessSearchInput";
import { CombinedFormData } from "../types";
import { BusinessDetails } from "../business-search/types";

interface BusinessInfoFieldsProps {
  formData: CombinedFormData;
  isSubmitting: boolean;
  onChange: (field: keyof CombinedFormData, value: string) => void;
}

export const BusinessInfoFields = ({ formData, isSubmitting, onChange }: BusinessInfoFieldsProps) => {
  const handleBusinessSelect = (details: BusinessDetails) => {
    if (!details) return;
    
    console.log('Business details received:', details);

    // Update business name, website, and phone
    onChange('businessName', details.name);
    onChange('websiteUrl', details.website || '');
    onChange('businessPhoneNumber', details.formatted_phone_number || '');

    // Try to determine business category from types
    if (details.types && details.types.length > 0) {
      const category = details.types[0]
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      onChange('businessCategory', category);
    }

    // Handle address components
    if (details.address_components) {
      let streetNumber = '';
      let route = '';
      let city = '';
      let state = '';
      let zipCode = '';
      let unit = '';

      details.address_components.forEach(component => {
        const types = component.types;
        
        if (types.includes('street_number')) {
          streetNumber = component.long_name;
        }
        if (types.includes('route')) {
          route = component.long_name;
        }
        if (types.includes('subpremise')) {
          unit = component.long_name;
        }
        if (types.includes('locality')) {
          city = component.long_name;
        }
        if (types.includes('administrative_area_level_1')) {
          state = component.short_name; // Using short_name for state abbreviation (e.g., 'TX' instead of 'Texas')
        }
        if (types.includes('postal_code')) {
          zipCode = component.long_name;
        }
      });

      // Build the complete street address
      const addressParts = [streetNumber, route];
      if (unit) {
        addressParts.push(`#${unit}`);
      }
      const streetAddress = addressParts.filter(Boolean).join(' ');

      console.log('Parsed address components:', {
        streetAddress,
        city,
        state,
        zipCode
      });

      // Update all address fields
      onChange('streetAddress', streetAddress);
      onChange('city', city);
      onChange('state', state);
      onChange('zipCode', zipCode);
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold pt-6">Business Information</h2>
      <div className="grid md:grid-cols-2 gap-4">
        <BusinessSearchInput onBusinessSelect={handleBusinessSelect} />
        
        <div className="space-y-2">
          <Label htmlFor="businessName">Business Name <span className="text-red-500">*</span></Label>
          <Input
            id="businessName"
            type="text"
            value={formData.businessName}
            onChange={(e) => onChange('businessName', e.target.value)}
            placeholder="Enter your business name"
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="businessCategory">Business Category <span className="text-red-500">*</span></Label>
          <Input
            id="businessCategory"
            type="text"
            value={formData.businessCategory}
            onChange={(e) => onChange('businessCategory', e.target.value)}
            placeholder="e.g., Restaurant, Retail, Services"
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="websiteUrl">Website URL</Label>
          <Input
            id="websiteUrl"
            type="url"
            value={formData.websiteUrl}
            onChange={(e) => onChange('websiteUrl', e.target.value)}
            placeholder="Enter your website URL"
            disabled={isSubmitting}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="businessPhoneNumber">Business Phone Number</Label>
          <Input
            id="businessPhoneNumber"
            type="tel"
            value={formData.businessPhoneNumber}
            onChange={(e) => onChange('businessPhoneNumber', e.target.value)}
            placeholder="Enter business phone number"
            disabled={isSubmitting}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="streetAddress">Street Address <span className="text-red-500">*</span></Label>
          <Input
            id="streetAddress"
            type="text"
            value={formData.streetAddress}
            onChange={(e) => onChange('streetAddress', e.target.value)}
            placeholder="Enter your street address"
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="city">City <span className="text-red-500">*</span></Label>
          <Input
            id="city"
            type="text"
            value={formData.city}
            onChange={(e) => onChange('city', e.target.value)}
            placeholder="Enter your city"
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="state">State <span className="text-red-500">*</span></Label>
          <Input
            id="state"
            type="text"
            value={formData.state}
            onChange={(e) => onChange('state', e.target.value)}
            placeholder="Enter your state"
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="zipCode">ZIP Code <span className="text-red-500">*</span></Label>
          <Input
            id="zipCode"
            type="text"
            value={formData.zipCode}
            onChange={(e) => onChange('zipCode', e.target.value)}
            placeholder="Enter your ZIP code"
            disabled={isSubmitting}
            required
          />
        </div>
      </div>
    </div>
  );
};