import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";

interface BillingFormProps {
  onSubmit: (data: {
    cardNumber: string;
    expiryDate: string;
    cvv: string;
    partnerCode?: string;
    billingAddress: string;
    billingCity: string;
    billingState: string;
    billingZipCode: string;
  }) => void;
  isProcessing: boolean;
}

export const BillingForm = ({ onSubmit, isProcessing }: BillingFormProps) => {
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [partnerCode, setPartnerCode] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingZipCode, setBillingZipCode] = useState("");
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Basic validation
      if (!cardNumber.match(/^\d{16}$/)) {
        throw new Error("Invalid card number");
      }
      if (!expiryDate.match(/^(0[1-9]|1[0-2])\/([0-9]{2})$/)) {
        throw new Error("Invalid expiry date (MM/YY)");
      }
      if (!cvv.match(/^\d{3,4}$/)) {
        throw new Error("Invalid CVV");
      }
      if (!billingAddress || !billingCity || !billingState || !billingZipCode) {
        throw new Error("Please fill in all billing address fields");
      }

      await onSubmit({
        cardNumber,
        expiryDate,
        cvv,
        partnerCode: partnerCode || undefined,
        billingAddress,
        billingCity,
        billingState,
        billingZipCode,
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  return (
    <form id="billing-form" onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-4">
        <h3 className="font-medium">Card Information</h3>
        <Input
          type="text"
          placeholder="Card Number"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value.replace(/\D/g, '').slice(0, 16))}
          required
          maxLength={16}
          pattern="\d*"
          aria-label="Card number"
          disabled={isProcessing}
        />
        <div className="grid grid-cols-2 gap-4">
          <Input
            type="text"
            placeholder="MM/YY"
            value={expiryDate}
            onChange={(e) => {
              let value = e.target.value.replace(/\D/g, '');
              if (value.length >= 2) {
                value = value.slice(0, 2) + '/' + value.slice(2, 4);
              }
              setExpiryDate(value);
            }}
            required
            maxLength={5}
            aria-label="Expiry date"
            disabled={isProcessing}
          />
          <Input
            type="text"
            placeholder="CVV"
            value={cvv}
            onChange={(e) => setCvv(e.target.value.replace(/\D/g, '').slice(0, 4))}
            required
            maxLength={4}
            pattern="\d*"
            aria-label="CVV"
            disabled={isProcessing}
          />
        </div>
      </div>

      <div className="space-y-4">
        <h3 className="font-medium">Billing Address</h3>
        <Input
          type="text"
          placeholder="Street Address"
          value={billingAddress}
          onChange={(e) => setBillingAddress(e.target.value)}
          required
          aria-label="Billing address"
          disabled={isProcessing}
        />
        <div className="grid grid-cols-2 gap-4">
          <Input
            type="text"
            placeholder="City"
            value={billingCity}
            onChange={(e) => setBillingCity(e.target.value)}
            required
            aria-label="City"
            disabled={isProcessing}
          />
          <Input
            type="text"
            placeholder="State"
            value={billingState}
            onChange={(e) => setBillingState(e.target.value)}
            required
            aria-label="State"
            disabled={isProcessing}
          />
        </div>
        <Input
          type="text"
          placeholder="ZIP Code"
          value={billingZipCode}
          onChange={(e) => setBillingZipCode(e.target.value)}
          required
          aria-label="ZIP code"
          disabled={isProcessing}
        />
      </div>

      <div>
        <Input
          type="text"
          placeholder="Partner Code (Optional)"
          value={partnerCode}
          onChange={(e) => setPartnerCode(e.target.value)}
          aria-label="Partner code"
          disabled={isProcessing}
        />
      </div>

      <Button 
        type="submit" 
        className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90"
        disabled={isProcessing}
      >
        {isProcessing ? "Processing..." : "Complete Checkout"}
      </Button>
    </form>
  );
};