import { useQuery } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { MapPin } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";

export const BillingAddress = () => {
  const { t } = useTranslation();
  
  const { data: subscription } = useQuery({
    queryKey: ['subscription'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");

      const { data, error } = await supabase
        .from('subscriptions')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      return data;
    }
  });

  return (
    <Card className="p-6 bg-white shadow-sm border border-gray-100">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-3 rounded-full bg-primary/10">
          <MapPin className="h-6 w-6 text-primary" />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">{t('billing.billingAddress.title')}</h2>
          <p className="text-sm text-gray-500">{t('billing.billingAddress.subtitle')}</p>
        </div>
      </div>

      {subscription ? (
        <div className="space-y-2">
          <p className="text-gray-600">{subscription.billing_street_address}</p>
          <p className="text-gray-600">
            {subscription.billing_city}, {subscription.billing_state} {subscription.billing_zip_code}
          </p>
        </div>
      ) : (
        <div className="text-center py-4">
          <p className="text-gray-500">{t('billing.billingAddress.noAddress')}</p>
        </div>
      )}
    </Card>
  );
};