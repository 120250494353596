import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface PaymentFormProps {
  packageType: string;
  amount: number;
  onSuccess: () => void;
  customerInfo?: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
  };
}

export const PaymentForm = ({ packageType, amount, onSuccess, customerInfo }: PaymentFormProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const { toast } = useToast();

  const loadAcceptJs = async () => {
    if (!(window as any).Accept) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "https://jstest.authorize.net/v3/Accept.js"; // Updated to v3 and HTTPS
        script.async = true;
        script.crossOrigin = "anonymous";
        script.integrity = "sha384-"; // You would need to add the actual integrity hash
        
        script.onload = () => resolve(true);
        script.onerror = () => reject(new Error("Failed to load Accept.js"));
        
        document.head.appendChild(script);
      });
    }
    return Promise.resolve(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      // Load Accept.js securely
      await loadAcceptJs();

      // Generate payment nonce
      const secureData = {
        cardData: {
          cardNumber: cardNumber.replace(/\s/g, ''),
          month: expiryDate.split('/')[0],
          year: '20' + expiryDate.split('/')[1],
          cardCode: cvv
        }
      };

      const response = await new Promise((resolve, reject) => {
        (window as any).Accept.dispatchData(secureData, (response: any) => {
          if (response.messages.resultCode === 'Error') {
            reject(new Error(response.messages.message[0].text));
          }
          resolve(response);
        });
      });

      // Process payment using Edge Function
      const { data, error } = await supabase.functions.invoke('process-payment', {
        body: {
          packageType,
          amount,
          paymentNonce: (response as any).opaqueData.dataValue,
          billingInfo: {
            cardNumber: cardNumber.slice(-4),
            firstName: customerInfo?.firstName,
            lastName: customerInfo?.lastName,
            email: customerInfo?.email,
            phoneNumber: customerInfo?.phoneNumber,
          }
        }
      });

      if (error) throw error;
      if (!data?.success) throw new Error(data?.error || 'Payment processing failed');

      toast({
        title: "Payment Successful",
        description: "Your subscription has been activated.",
      });

      onSuccess();
    } catch (error: any) {
      console.error('Payment error:', error);
      toast({
        title: "Payment Failed",
        description: error.message || "There was an error processing your payment",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const formatCardNumber = (value: string) => {
    // Remove any non-digit characters
    const v = value.replace(/\D/g, '');
    
    // Split the card number into groups of 4
    const parts = [];
    for (let i = 0; i < v.length && i < 16; i += 4) {
      parts.push(v.slice(i, i + 4));
    }
    
    // Join the parts with spaces
    return parts.join(' ');
  };

  const formatExpiryDate = (value: string) => {
    const v = value.replace(/\D/g, '');
    if (v.length >= 2) {
      return v.slice(0, 2) + '/' + v.slice(2, 4);
    }
    return v;
  };

  return (
    <Card className="p-6">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="cardNumber">Card Number</Label>
          <Input
            id="cardNumber"
            type="text"
            value={cardNumber}
            onChange={(e) => setCardNumber(formatCardNumber(e.target.value))}
            placeholder="1234 5678 9012 3456"
            maxLength={19}
            required
            disabled={isProcessing}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="expiryDate">Expiry Date</Label>
            <Input
              id="expiryDate"
              type="text"
              value={expiryDate}
              onChange={(e) => setExpiryDate(formatExpiryDate(e.target.value))}
              placeholder="MM/YY"
              maxLength={5}
              required
              disabled={isProcessing}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="cvv">CVV</Label>
            <Input
              id="cvv"
              type="text"
              value={cvv}
              onChange={(e) => setCvv(e.target.value.replace(/\D/g, '').slice(0, 4))}
              placeholder="123"
              maxLength={4}
              required
              disabled={isProcessing}
            />
          </div>
        </div>

        <Button 
          type="submit" 
          className="w-full"
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : `Pay $${amount}`}
        </Button>
      </form>
    </Card>
  );
};