import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { User, Settings as SettingsIcon, Lock } from "lucide-react";
import { AppSidebar } from "@/components/AppSidebar";
import { SidebarProvider } from "@/components/ui/sidebar";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const { toast } = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Add ResizeObserver cleanup
  useEffect(() => {
    // Disconnect all ResizeObservers when component unmounts
    return () => {
      if (window.ResizeObserver) {
        const resizeObservers = (window as any).__resizeObservers__ || [];
        resizeObservers.forEach((observer: any) => {
          if (observer && typeof observer.disconnect === 'function') {
            observer.disconnect();
          }
        });
      }
    };
  }, []);

  const updateProfile = async () => {
    try {
      setLoading(true);
      const { error } = await supabase
        .from('profiles')
        .update({
          first_name: firstName,
          last_name: lastName,
        })
        .eq('id', (await supabase.auth.getUser()).data.user?.id);

      if (error) throw error;

      toast({
        title: t("settings.personalInfo.success"),
        description: t("settings.personalInfo.success"),
      });
    } catch (error) {
      toast({
        title: t("settings.personalInfo.error"),
        description: t("settings.personalInfo.error"),
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });

      if (error) throw error;

      toast({
        title: t("settings.security.resetPasswordSuccess"),
        description: t("settings.security.resetPasswordSuccess"),
      });
    } catch (error) {
      toast({
        title: t("settings.security.resetPasswordError"),
        description: t("settings.security.resetPasswordError"),
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-gray-50">
        <AppSidebar />
        <div className="flex-1 p-8">
          <div className="max-w-6xl mx-auto space-y-8">
            <div className="mb-8">
              <h1 className="text-2xl font-semibold text-gray-900">{t("settings.title")}</h1>
              <p className="text-sm text-gray-500">{t("settings.subtitle")}</p>
            </div>
            
            <div className="grid gap-8 md:grid-cols-2">
              <Card className="p-6 bg-white shadow-sm border border-gray-100">
                <div className="flex items-center gap-3 mb-6">
                  <div className="p-3 rounded-full bg-primary/10">
                    <User className="h-6 w-6 text-primary" />
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold text-gray-900">{t("settings.personalInfo.title")}</h2>
                    <p className="text-sm text-gray-500">{t("settings.personalInfo.subtitle")}</p>
                  </div>
                </div>
                
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t("settings.personalInfo.firstName")}
                    </label>
                    <Input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder={t("settings.personalInfo.firstName")}
                      className="w-full"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t("settings.personalInfo.lastName")}
                    </label>
                    <Input
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder={t("settings.personalInfo.lastName")}
                      className="w-full"
                    />
                  </div>
                  
                  <Button
                    onClick={updateProfile}
                    disabled={loading}
                    className="w-full bg-primary hover:bg-primary/90"
                  >
                    {loading ? t("settings.personalInfo.saving") : t("settings.personalInfo.saveChanges")}
                  </Button>
                </div>
              </Card>

              <Card className="p-6 bg-white shadow-sm border border-gray-100">
                <div className="flex items-center gap-3 mb-6">
                  <div className="p-3 rounded-full bg-primary/10">
                    <Lock className="h-6 w-6 text-primary" />
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold text-gray-900">{t("settings.security.title")}</h2>
                    <p className="text-sm text-gray-500">{t("settings.security.subtitle")}</p>
                  </div>
                </div>
                
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t("settings.personalInfo.email")}
                    </label>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={t("settings.personalInfo.email")}
                      className="w-full"
                    />
                    <p className="mt-1 text-sm text-gray-500">
                      {t("settings.personalInfo.emailHelp")}
                    </p>
                  </div>
                  
                  <Button
                    onClick={resetPassword}
                    disabled={loading}
                    variant="outline"
                    className="w-full border-gray-200 hover:bg-gray-50"
                  >
                    {t("settings.security.resetPassword")}
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default Settings;