export const settings = {
  title: "Settings",
  subtitle: "Manage your account settings and preferences",
  personalInfo: {
    title: "Personal Information",
    subtitle: "Update your personal details",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    emailHelp: "Your email address is associated with your account and cannot be changed.",
    phoneNumber: "Phone Number",
    role: "Role",
    saveChanges: "Save Changes",
    saving: "Saving...",
    success: "Settings updated successfully",
    error: "Failed to update settings"
  },
  security: {
    title: "Security",
    subtitle: "Manage your password and security settings",
    resetPassword: "Reset Password",
    resetPasswordSuccess: "Password reset email sent",
    resetPasswordError: "Failed to send reset password email"
  }
};