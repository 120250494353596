import { Home, User, Settings, Building2, CreditCard, Share2, BookOpen, ShieldCheck, LifeBuoy, LogOut, PlayCircle, Users } from "lucide-react";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarFooter,
} from "@/components/ui/sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from "./LanguageSelector";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "./ui/button";

type MenuItem = {
  titleKey?: string;
  icon?: React.ComponentType<{ className?: string }>;
  url?: string;
  divider?: boolean;
};

const baseMenuItems: MenuItem[] = [
  { titleKey: "common.dashboard", icon: Home, url: "/dashboard" },
  { titleKey: "common.resources", icon: BookOpen, url: "/resources" },
  { titleKey: "common.videos", icon: PlayCircle, url: "/videos" },
  { divider: true },
  { titleKey: "common.myAccount", icon: User, url: "/my-account" },
  { titleKey: "common.businessProfile", icon: Building2, url: "/business-profile" },
  { titleKey: "common.integrations", icon: Share2, url: "/integrations" },
  { titleKey: "common.billing", icon: CreditCard, url: "/billing" },
  { titleKey: "common.settings", icon: Settings, url: "/settings" },
  { titleKey: "common.support", icon: LifeBuoy, url: "/support" },
];

const adminMenuItem: MenuItem = { titleKey: "common.adminPanel", icon: ShieldCheck, url: "/admin" };
const partnerMenuItem: MenuItem = { titleKey: "common.partner", icon: Users, url: "/partner" };

export function AppSidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: profile } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();

      if (error) throw error;
      return data;
    },
  });

  const menuItems = [...baseMenuItems];
  
  // Add Partner menu item for partners and admins
  if (profile?.is_partner || profile?.is_admin) {
    const insertIndex = menuItems.findIndex(item => item.divider) + 1;
    menuItems.splice(insertIndex, 0, partnerMenuItem);
  }
  
  // Add Admin menu item for admins
  if (profile?.is_admin) {
    menuItems.splice(menuItems.length - 1, 0, adminMenuItem);
  }

  const isActive = (url: string) => {
    if (url.startsWith('#')) return false;
    return location.pathname === url;
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/login');
  };

  return (
    <Sidebar className="border-r border-gray-100 bg-white">
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel className="px-6 py-4 mt-4">
            <img 
              src="https://woobound.com/wp-content/uploads/2024/12/woobound-marketing-logo-black-250.png" 
              alt="Woobound Marketing"
              className="h-8 mb-8"
            />
          </SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              {menuItems.map((item, index) => (
                item.divider ? (
                  <hr key={index} className="my-4 border-gray-100" />
                ) : (
                  <SidebarMenuItem key={item.titleKey} className="px-3">
                    <SidebarMenuButton asChild>
                      <Link 
                        to={item.url!} 
                        className={`flex items-center gap-3 px-4 py-3 rounded-lg transition-colors duration-200 ${
                          isActive(item.url!)
                            ? 'bg-orange-50 text-[#F97316] hover:bg-orange-100'
                            : 'text-[#8E9196] hover:bg-gray-50 hover:text-gray-900'
                        }`}
                      >
                        {item.icon && <item.icon className={`h-5 w-5 ${
                          isActive(item.url!)
                            ? 'text-[#F97316]'
                            : 'text-gray-500'
                        }`} />}
                        <span className="text-sm font-medium">
                          {t(item.titleKey!)}
                        </span>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                )
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter className="p-4 mt-auto border-t border-gray-100">
        <Button
          variant="ghost"
          className="w-full mb-4 text-[#8E9196] hover:text-gray-900 hover:bg-gray-50"
          onClick={handleLogout}
        >
          <LogOut className="w-5 h-5 mr-2" />
          {t('common.logout')}
        </Button>
        <LanguageSelector />
      </SidebarFooter>
    </Sidebar>
  );
}