import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { AlertCircle } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";

export const CancelSubscription = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const handleCancel = async () => {
    if (!confirm("Are you sure you want to cancel your subscription? This action cannot be undone.")) {
      return;
    }

    setIsLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");

      // Get the subscription
      const { data: subscription, error: fetchError } = await supabase
        .from('subscriptions')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (fetchError) throw fetchError;
      if (!subscription) throw new Error("No active subscription found");

      // Cancel the subscription via Edge Function
      const { error } = await supabase.functions.invoke('cancel-subscription', {
        body: { 
          subscriptionId: subscription.external_subscription_id 
        }
      });

      if (error) throw error;

      // Update subscription status in database
      const { error: updateError } = await supabase
        .from('subscriptions')
        .update({ 
          status: 'cancelled',
          cancellation_date: new Date().toISOString()
        })
        .eq('user_id', user.id)
        .single();

      if (updateError) throw updateError;

      toast({
        title: "Subscription Cancelled",
        description: "Your subscription has been cancelled successfully.",
      });

      queryClient.invalidateQueries({ queryKey: ['subscription'] });
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to cancel subscription",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="p-6 bg-white shadow-sm border border-gray-100">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-3 rounded-full bg-destructive/10">
          <AlertCircle className="h-6 w-6 text-destructive" />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">Cancel Subscription</h2>
          <p className="text-sm text-gray-500">Cancel your current subscription plan</p>
        </div>
      </div>

      <div className="space-y-4">
        <p className="text-gray-600">
          Warning: Cancelling your subscription will immediately stop all services. 
          This action cannot be undone.
        </p>
        <Button
          variant="destructive"
          onClick={handleCancel}
          disabled={isLoading}
        >
          {isLoading ? "Cancelling..." : "Cancel Subscription"}
        </Button>
      </div>
    </Card>
  );
};