interface ReviewInformationProps {
  personalInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  businessInfo: {
    businessName: string;
    website: string;
    category: string;
    address: string;
  };
  price: number;
}

export const ReviewInformation = ({ personalInfo, businessInfo, price }: ReviewInformationProps) => {
  return (
    <div className="bg-gray-50 p-4 rounded-lg space-y-4">
      <div>
        <h3 className="font-medium mb-2">Personal Information</h3>
        <p className="text-[#333333] font-medium">{personalInfo.firstName} {personalInfo.lastName}</p>
        <p className="text-[#666666]">{personalInfo.email}</p>
        <p className="text-[#666666]">{personalInfo.phone}</p>
      </div>
      
      <div>
        <h3 className="font-medium mb-2">Business Information</h3>
        <p className="text-[#666666]">{businessInfo.businessName}</p>
        <p className="text-[#666666]">{businessInfo.website}</p>
        <p className="text-[#666666]">{businessInfo.category}</p>
        <p className="text-[#666666]">{businessInfo.address}</p>
      </div>

      <div>
        <h3 className="font-medium mb-2">Package Details</h3>
        <p className="text-[#333333] font-medium">${price}/month</p>
      </div>
    </div>
  );
};