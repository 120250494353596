import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Button } from "@/components/ui/button";
import { RichTextEditor } from "@/components/admin/articles/RichTextEditor";
import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

const ArticlePage = () => {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [editedArticle, setEditedArticle] = useState<any>(null);

  const { data: profile } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;
      
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();
      
      if (error) throw error;
      return data;
    },
  });

  const { data: article, isLoading } = useQuery({
    queryKey: ['article', articleId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('articles')
        .select('*')
        .eq('id', articleId)
        .single();
      
      if (error) throw error;
      return data;
    },
  });

  const updateArticle = useMutation({
    mutationFn: async (updatedArticle: any) => {
      const { data, error } = await supabase
        .from('articles')
        .update(updatedArticle)
        .eq('id', articleId)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['article', articleId] });
      setIsEditing(false);
      toast({
        title: "Success",
        description: "Article updated successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update article",
        variant: "destructive",
      });
    },
  });

  const handleEdit = () => {
    setEditedArticle(article);
    setIsEditing(true);
  };

  const handleSave = () => {
    updateArticle.mutate(editedArticle);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedArticle(null);
  };

  const content = isLoading ? (
    <div>Loading...</div>
  ) : !article ? (
    <div>Article not found</div>
  ) : isEditing ? (
    <Card className="p-6 max-w-4xl mx-auto">
      <div className="space-y-4">
        <Input
          value={editedArticle.title}
          onChange={(e) => setEditedArticle({ ...editedArticle, title: e.target.value })}
          placeholder="Title"
          className="text-2xl font-bold"
        />
        <RichTextEditor
          content={editedArticle.description}
          onChange={(content) => setEditedArticle({ ...editedArticle, description: content })}
        />
        <div className="flex justify-end gap-2">
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave}>
            Save Changes
          </Button>
        </div>
      </div>
    </Card>
  ) : (
    <Card className="p-6 max-w-4xl mx-auto">
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold text-gray-900">{article.title}</h1>
          {profile?.is_admin && (
            <Button onClick={handleEdit}>
              Edit Article
            </Button>
          )}
        </div>
        <div 
          className="prose prose-slate max-w-none"
          dangerouslySetInnerHTML={{ __html: article.description }} 
        />
      </div>
    </Card>
  );

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-gray-50">
        <AppSidebar />
        <div className="flex-1 p-8">
          {content}
        </div>
      </div>
    </SidebarProvider>
  );
};

export default ArticlePage;