import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  onGetStartedClick: () => void;
}

export const Header = ({ onGetStartedClick }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <header className="relative bg-gradient-to-r from-[#221F26]/5 to-[#403E43]/5 py-32 px-4">
      <div className="container mx-auto max-w-6xl">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="text-left space-y-6">
            <h1 className="text-5xl md:text-6xl font-bold text-[#221F26] leading-tight animate-fade-in">
              <span className="text-primary">{t('landing.hero.title')}</span>
            </h1>
            <p className="text-xl text-[#403E43]/80 leading-relaxed max-w-xl animate-fade-in delay-100">
              {t('landing.hero.subtitle')}
            </p>
            <div className="flex gap-4 pt-4 animate-fade-in delay-200">
              <Button
                size="lg"
                className="bg-primary hover:bg-primary/90 text-white px-8 py-6 text-lg shadow-lg hover:shadow-xl transition-all"
                onClick={onGetStartedClick}
              >
                {t('landing.hero.cta')} <ArrowRight className="ml-2" />
              </Button>
            </div>
          </div>
          <div className="hidden md:block relative animate-fade-in delay-300">
            <div className="absolute -top-8 -left-8 w-72 h-72 bg-primary/10 rounded-full blur-3xl"></div>
            <img 
              src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2426&q=80"
              alt="SEO Analytics Dashboard"
              className="rounded-2xl shadow-2xl"
            />
          </div>
        </div>
      </div>
    </header>
  );
};