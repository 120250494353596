import { FilterOptions } from "./types";
import { SearchBar } from "./SearchBar";
import { SearchHistory } from "./SearchHistory";
import { Download } from "lucide-react";
import { Button } from "@/components/ui/button";
import { BusinessWithProfile, SearchField } from "./types";

interface BusinessTableFiltersProps {
  searchQuery: string;
  searchField: SearchField;
  filterOptions: FilterOptions;
  searchHistory: string[];
  filteredBusinesses: BusinessWithProfile[];
  onSearchQueryChange: (value: string) => void;
  onSearchFieldChange: (value: SearchField) => void;
  onFilterOptionsChange: (options: FilterOptions) => void;
  onSearch: () => void;
  onHistoryItemClick: (query: string) => void;
  onExport: () => void;
}

export const BusinessTableFilters = ({
  searchQuery,
  searchField,
  filterOptions,
  searchHistory,
  filteredBusinesses,
  onSearchQueryChange,
  onSearchFieldChange,
  onFilterOptionsChange,
  onSearch,
  onHistoryItemClick,
  onExport,
}: BusinessTableFiltersProps) => {
  return (
    <div className="space-y-4">
      <SearchBar
        searchQuery={searchQuery}
        searchField={searchField}
        filterOptions={filterOptions}
        onSearchQueryChange={onSearchQueryChange}
        onSearchFieldChange={onSearchFieldChange}
        onFilterOptionsChange={onFilterOptionsChange}
        onSearch={onSearch}
      />

      <SearchHistory
        searchHistory={searchHistory}
        onHistoryItemClick={onHistoryItemClick}
      />

      <div className="flex justify-between items-center">
        <div className="text-sm text-gray-500">
          Found {filteredBusinesses.length} business{filteredBusinesses.length !== 1 ? 'es' : ''}
        </div>
        <Button
          variant="outline"
          onClick={onExport}
          disabled={!filteredBusinesses.length}
        >
          <Download className="h-4 w-4 mr-2" />
          Export
        </Button>
      </div>
    </div>
  );
};