import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Globe, BarChart3, LayoutDashboard, Languages, History, TrendingUp } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const featureIcons = {
  localSeo: Globe,
  analytics: BarChart3,
  dashboard: LayoutDashboard,
  multiLanguage: Languages,
  experience: History,
  roi: TrendingUp,
};

export const Features = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const features = [
    {
      key: 'localSeo',
      icon: featureIcons.localSeo,
    },
    {
      key: 'analytics',
      icon: featureIcons.analytics,
    },
    {
      key: 'dashboard',
      icon: featureIcons.dashboard,
    },
    {
      key: 'multiLanguage',
      icon: featureIcons.multiLanguage,
    },
    {
      key: 'experience',
      icon: featureIcons.experience,
    },
    {
      key: 'roi',
      icon: featureIcons.roi,
    },
  ];

  return (
    <section className="py-32 px-4 bg-white">
      <div className="container mx-auto max-w-6xl">
        <div className="text-center max-w-3xl mx-auto mb-20">
          <h2 className="text-4xl font-bold mb-6 text-[#221F26]">{t('landing.features.title')}</h2>
          <p className="text-xl text-[#403E43]/80 leading-relaxed">
            {t('landing.features.subtitle')}
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-20">
          {features.map((feature) => (
            <Card key={feature.key} className="p-8 hover:shadow-lg transition-shadow border-[#F1F0FB] bg-gradient-to-b from-white to-[#F1F0FB]/30">
              <div className="mb-6">
                <feature.icon className="w-12 h-12 text-primary" />
              </div>
              <h3 className="text-xl font-semibold mb-4 text-[#221F26]">
                {t(`landing.features.items.${feature.key}.title`)}
              </h3>
              <p className="text-[#403E43]/80">
                {t(`landing.features.items.${feature.key}.description`)}
              </p>
            </Card>
          ))}
        </div>

        <div className="text-center">
          <Button 
            className="bg-primary hover:bg-primary/90 text-white px-8 py-6 text-lg shadow-lg hover:shadow-xl transition-all"
            onClick={() => navigate('/signup/maps_seo')}
          >
            {t('landing.features.cta')}
          </Button>
        </div>
      </div>
    </section>
  );
};