export const dashboard = {
  title: 'Dashboard',
  welcome: 'Welcome back! Here\'s your SEO overview.',
  noTasks: 'No tasks available at the moment. Check back later!',
  onboardingProgress: {
    title: 'Task Progress',
    subtitle: 'Track your progress',
    tasks: {
      selectPackage: 'Select package',
      setupAccount: 'Setup your account',
      grantAccess: 'Grant Google access',
      connectNow: 'Connect Now',
    },
    status: {
      completed: 'Completed',
      in_progress: 'In Progress',
      not_started: 'Not Started',
      pending: 'Pending',
    }
  },
  nextSteps: {
    title: 'Next Steps',
    subtitle: 'Resources to help you get started',
    articles: {
      readMore: 'Read More',
    }
  },
  metrics: {
    averagePosition: {
      title: 'Average Position',
      trend: '2.1%'
    },
    keywordsTracked: {
      title: 'Keywords Tracked',
      trend: '12 new'
    },
    organicTraffic: {
      title: 'Organic Traffic',
      trend: '18.2%'
    },
    backlinks: {
      title: 'Backlinks',
      trend: '4 new'
    }
  }
};