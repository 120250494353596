import { Filter } from "lucide-react";
import { TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { BusinessWithProfile } from "./types";

interface BusinessTableHeaderProps {
  sortField: keyof BusinessWithProfile;
  sortDirection: 'asc' | 'desc';
  onSort: (field: keyof BusinessWithProfile) => void;
}

export const BusinessTableHeader = ({
  sortField,
  sortDirection,
  onSort,
}: BusinessTableHeaderProps) => {
  const headers = [
    ['business_name', 'Business Name'],
    ['profiles', 'Owner'],
    ['business_email', 'Business Email'],
    ['phone_number', 'Phone'],
    ['street_address', 'Street Address'],
    ['city', 'City'],
    ['state', 'State'],
    ['zip_code', 'ZIP Code'],
    ['category', 'Category'],
    ['website_url', 'Website'],
    ['facebook_url', 'Facebook'],
    ['instagram_url', 'Instagram'],
    ['opening_date', 'Opening Date'],
    ['opening_hours', 'Opening Hours'],
    ['short_description', 'Short Description'],
    ['long_description', 'Long Description'],
    ['business_services', 'Services'],
    ['desired_keywords', 'Keywords'],
    ['payment_methods', 'Payment Methods'],
    ['promotions', 'Promotions'],
    ['seo_campaign_goal', 'SEO Goal'],
    ['status', 'Status'],
    ['actions', 'Actions']
  ] as const;

  return (
    <TableHeader>
      <TableRow>
        <TableHead className="w-48">Field</TableHead>
        <TableHead>Value</TableHead>
      </TableRow>
    </TableHeader>
  );
};