export const WooboundLogo = () => {
  return (
    <div className="flex justify-center mb-12">
      <img 
        src="/lovable-uploads/85cb624a-def7-457f-a6ed-b9431ab454dd.png" 
        alt="Woobound Logo" 
        className="h-12"
      />
    </div>
  );
};