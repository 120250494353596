import { ReactNode, useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { AuthError, AuthChangeEvent } from "@supabase/supabase-js";

interface SessionProviderProps {
  children: (props: {
    session: any;
    profile: any;
    loading: boolean;
  }) => ReactNode;
}

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const [session, setSession] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const navigate = useNavigate();
  const { toast } = useToast();

  const { data: profile } = useQuery({
    queryKey: ['profile', session?.user?.id],
    queryFn: async () => {
      if (!session?.user?.id) return null;
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();
      if (error) throw error;
      return data;
    },
    enabled: !!session?.user?.id,
  });

  // Handle user activity
  const handleUserActivity = () => {
    setLastActivity(Date.now());
  };

  // Check for session timeout
  useEffect(() => {
    const checkSessionTimeout = setInterval(() => {
      const inactiveTime = Date.now() - lastActivity;
      if (session && inactiveTime > 15 * 60 * 1000) { // 15 minutes
        handleSignOut();
      }
    }, 1000);

    return () => clearInterval(checkSessionTimeout);
  }, [session, lastActivity]);

  // Handle sign out
  const handleSignOut = async () => {
    await supabase.auth.signOut();
    setSession(null);
    navigate('/login');
  };

  // Add event listeners for user activity
  useEffect(() => {
    const events = ['mousedown', 'keydown', 'scroll', 'touchstart'];
    events.forEach(event => {
      window.addEventListener(event, handleUserActivity);
    });

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, handleUserActivity);
      });
    };
  }, []);

  // Initialize session and handle auth state changes
  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session: initialSession }, error }) => {
      if (error) {
        console.error('Error getting session:', error);
        handleSignOut();
        return;
      }
      setSession(initialSession);
      setLoading(false);
    });

    // Subscribe to auth state changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event: AuthChangeEvent, newSession) => {
      console.log('Auth state change:', event);
      
      if (event === 'TOKEN_REFRESHED') {
        setSession(newSession);
      } else if (event === 'SIGNED_OUT') {
        setSession(null);
        navigate('/login');
      } else if (event === 'SIGNED_IN') {
        setSession(newSession);
      }

      // Handle session errors
      if (event === 'SIGNED_OUT' && !newSession) {
        console.error('Session expired');
        toast({
          title: "Session Expired",
          description: "Please sign in again to continue.",
          variant: "destructive",
        });
        handleSignOut();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return <>{children({ session, profile, loading })}</>;
};