import { useState } from "react";
import { TermsModal } from "../TermsModal";
import { useParams } from "react-router-dom";
import { BillingForm } from "./checkout/BillingForm";
import { ReviewInformation } from "./checkout/ReviewInformation";
import { TermsAgreement } from "./checkout/TermsAgreement";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

interface CheckoutStepProps {
  personalInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  businessInfo: {
    businessName: string;
    website: string;
    category: string;
    address: string;
  };
  onComplete: () => void;
}

const PACKAGE_PRICES = {
  maps_seo: 598,
  maps_website_seo: 998,
};

export const CheckoutStep = ({ personalInfo, businessInfo, onComplete }: CheckoutStepProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [modalContent, setModalContent] = useState<{
    isOpen: boolean;
    title: string;
    url: string;
  }>({
    isOpen: false,
    title: "",
    url: "",
  });

  const { packageType } = useParams<{ packageType: string }>();
  const price = PACKAGE_PRICES[packageType as keyof typeof PACKAGE_PRICES] || 0;
  const { toast } = useToast();

  const handleSubmit = async (formData: {
    cardNumber: string;
    expiryDate: string;
    cvv: string;
    partnerCode?: string;
    billingAddress: string;
    billingCity: string;
    billingState: string;
    billingZipCode: string;
  }) => {
    if (!termsAgreed) {
      toast({
        title: "Terms Required",
        description: "Please agree to the terms and conditions",
        variant: "destructive",
      });
      return;
    }

    setIsProcessing(true);

    try {
      console.log('Sending payment request to Edge Function...');
      
      const { data, error } = await supabase.functions.invoke('process-payment', {
        body: {
          packageType,
          cardInfo: {
            cardNumber: formData.cardNumber,
            expiryDate: formData.expiryDate,
            cvv: formData.cvv,
          },
          billingInfo: {
            address: formData.billingAddress,
            city: formData.billingCity,
            state: formData.billingState,
            zipCode: formData.billingZipCode,
            firstName: personalInfo.firstName,
            lastName: personalInfo.lastName,
            email: personalInfo.email,
            phoneNumber: personalInfo.phone,
          },
          personalInfo,
          businessInfo,
          amount: price,
        },
      });

      console.log('Edge Function response:', { data, error });

      if (error) {
        console.error('Edge Function error:', error);
        throw new Error(error.message);
      }
      
      if (!data?.success) {
        console.error('Payment processing failed:', data?.error);
        throw new Error(data?.error || 'Payment processing failed');
      }

      toast({
        title: "Payment Successful",
        description: "Your payment has been processed successfully.",
      });

      // Update onboarding session to mark checkout as completed
      const { error: updateError } = await supabase
        .from('onboarding_sessions')
        .update({ checkout_completed: true })
        .eq('user_id', (await supabase.auth.getUser()).data.user?.id)
        .single();

      if (updateError) {
        console.error('Error updating onboarding session:', updateError);
      }

      onComplete();
    } catch (error: any) {
      console.error('Payment error:', error);
      toast({
        title: "Payment Failed",
        description: error.message || "There was an error processing your payment. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const openModal = (title: string, url: string) => {
    setModalContent({
      isOpen: true,
      title,
      url,
    });
  };

  const closeModal = () => {
    setModalContent(prev => ({ ...prev, isOpen: false }));
  };

  return (
    <div className="space-y-8 pb-12">
      <h2 className="text-xl font-semibold text-[#333333] mb-4">Review & Checkout</h2>
      
      <ReviewInformation
        personalInfo={personalInfo}
        businessInfo={businessInfo}
        price={price}
      />

      <BillingForm onSubmit={handleSubmit} isProcessing={isProcessing} />

      <TermsAgreement
        termsAgreed={termsAgreed}
        onTermsChange={setTermsAgreed}
        onOpenModal={openModal}
      />

      <TermsModal
        isOpen={modalContent.isOpen}
        onClose={closeModal}
        title={modalContent.title}
        url={modalContent.url}
      />
    </div>
  );
};