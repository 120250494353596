import { BarChart2, Globe, Search, TrendingUp } from "lucide-react";
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { MetricCard } from "@/components/MetricCard";
import { Card } from "@/components/ui/card";
import { TodoList } from "@/components/dashboard/TodoList";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

const Dashboard = () => {
  const { t } = useTranslation();

  const { data: profile } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;
      
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();
      
      if (error) throw error;
      return data;
    },
  });

  return (
    <SidebarProvider>
      <div className="min-h-screen flex w-full bg-gray-50">
        <AppSidebar />
        <main className="flex-1 p-8 max-w-7xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">{t('dashboard.title')}</h1>
              <p className="text-gray-500 mt-1">
                Welcome back, {profile?.first_name || ''}! Here are the tasks we're working on this month.
              </p>
            </div>
            <SidebarTrigger />
          </div>

          <TodoList />

          {/* Welcome Section */}
          <div className="mt-8 bg-white rounded-lg border border-gray-100 shadow-sm transition-shadow hover:shadow-md">
            <div className="p-6 border-b border-gray-100">
              <h2 className="text-xl font-semibold text-gray-900">{t('dashboard.welcomeSection.title')}</h2>
              <p className="mt-1 text-sm text-gray-500">{t('dashboard.welcomeSection.subtitle')}</p>
            </div>
            <div className="p-6">
              <div className="grid md:grid-cols-2 gap-6">
                <div className="aspect-video rounded-lg overflow-hidden">
                  <iframe 
                    width="100%" 
                    height="100%" 
                    src="https://www.youtube.com/embed/your-video-id" 
                    title={t('dashboard.welcomeSection.title')}
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen
                    className="w-full h-full object-cover"
                  ></iframe>
                </div>
                <div className="flex flex-col justify-center">
                  <h3 className="text-lg font-semibold text-gray-900 mb-4">{t('dashboard.welcomeSection.title')}</h3>
                  <p className="text-gray-600 leading-relaxed">
                    {t('dashboard.welcomeSection.description')}
                  </p>
                  <p className="text-gray-600 leading-relaxed mt-4">
                    {t('dashboard.welcomeSection.videoDescription')}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4 mt-8">
            <MetricCard
              title={t('dashboard.metrics.averagePosition.title')}
              value="12.4"
              icon={<TrendingUp className="h-5 w-5 text-[#F97316]" />}
              trend="up"
              trendValue={t('dashboard.metrics.averagePosition.trend')}
              className="bg-white shadow-sm hover:shadow-md transition-shadow"
            />
            <MetricCard
              title={t('dashboard.metrics.keywordsTracked.title')}
              value="1,234"
              icon={<Search className="h-5 w-5 text-[#F97316]" />}
              trend="up"
              trendValue={t('dashboard.metrics.keywordsTracked.trend')}
              className="bg-white shadow-sm hover:shadow-md transition-shadow"
            />
            <MetricCard
              title={t('dashboard.metrics.organicTraffic.title')}
              value="45.2K"
              icon={<Globe className="h-5 w-5 text-[#F97316]" />}
              trend="up"
              trendValue={t('dashboard.metrics.organicTraffic.trend')}
              className="bg-white shadow-sm hover:shadow-md transition-shadow"
            />
            <MetricCard
              title={t('dashboard.metrics.backlinks.title')}
              value="892"
              icon={<BarChart2 className="h-5 w-5 text-[#F97316]" />}
              trend="up"
              trendValue={t('dashboard.metrics.backlinks.trend')}
              className="bg-white shadow-sm hover:shadow-md transition-shadow"
            />
          </div>

          <div className="mt-8 grid gap-6 md:grid-cols-2">
            <Card className="p-6 bg-white shadow-sm hover:shadow-md transition-shadow">
              <h2 className="text-xl font-semibold mb-4 text-gray-900">Recent Projects</h2>
              <div className="space-y-3">
                {["Project Alpha", "Project Beta", "Project Gamma"].map((project) => (
                  <div key={project} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                    <span className="text-gray-700">{project}</span>
                    <button className="text-[#F97316] hover:text-orange-600 font-medium">
                      View Details →
                    </button>
                  </div>
                ))}
              </div>
            </Card>

            <Card className="p-6 bg-white shadow-sm hover:shadow-md transition-shadow">
              <h2 className="text-xl font-semibold mb-4 text-gray-900">Top Keywords</h2>
              <div className="space-y-3">
                {[
                  { keyword: "SEO Services", position: 2 },
                  { keyword: "Digital Marketing", position: 5 },
                  { keyword: "Local SEO", position: 1 },
                ].map((item) => (
                  <div key={item.keyword} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                    <span className="text-gray-700">{item.keyword}</span>
                    <span className="font-medium text-gray-900">Position: {item.position}</span>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default Dashboard;