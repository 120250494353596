import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { BusinessWithProfile, SearchField, FilterOptions } from "./business-table/types";
import { exportToCSV, getCompletionStatus } from "./business-table/utils";
import { useToast } from "@/hooks/use-toast";
import { BusinessTableFilters } from "./business-table/BusinessTableFilters";
import { BusinessTableContent } from "./business-table/BusinessTableContent";

export const BusinessTable = () => {
  const { toast } = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchField, setSearchField] = useState<SearchField>("business_name");
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [sortField, setSortField] = useState<keyof BusinessWithProfile>("business_name");
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    completionStatus: 'all',
    category: '',
    dateRange: {
      from: null,
      to: null,
    },
  });

  const { data: businesses, isLoading, error } = useQuery({
    queryKey: ['business_profiles'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('business_profiles')
        .select(`
          *,
          profiles:user_id (
            email,
            first_name,
            last_name
          )
        `);
      if (error) throw error;
      return data as unknown as BusinessWithProfile[];
    },
  });

  const handleDeleteBusiness = async (businessId: string) => {
    const { error } = await supabase
      .from('business_profiles')
      .delete()
      .eq('id', businessId);

    if (error) {
      toast({
        title: "Error",
        description: "Failed to delete business profile",
        variant: "destructive",
      });
    } else {
      toast({
        title: "Success",
        description: "Business profile deleted successfully",
      });
    }
  };

  const handleSearch = () => {
    if (searchQuery && !searchHistory.includes(searchQuery)) {
      setSearchHistory(prev => [searchQuery, ...prev].slice(0, 5));
    }
  };

  const filteredBusinesses = businesses?.filter(business => {
    if (!searchQuery && filterOptions.completionStatus === 'all' && !filterOptions.category && !filterOptions.dateRange.from) {
      return true;
    }

    let matches = true;

    // Search query filter
    if (searchQuery) {
      const searchValue = business[searchField];
      const query = searchQuery.toLowerCase();
      
      if (searchValue === null) {
        matches = false;
      } else if (Array.isArray(searchValue)) {
        matches = searchValue.some(val => 
          val?.toString().toLowerCase().includes(query)
        );
      } else {
        matches = searchValue?.toString().toLowerCase().includes(query);
      }
    }

    // Completion status filter
    if (filterOptions.completionStatus !== 'all') {
      const status = getCompletionStatus(business);
      const isComplete = status.completed === status.total;
      matches = matches && (
        (filterOptions.completionStatus === 'complete' && isComplete) ||
        (filterOptions.completionStatus === 'incomplete' && !isComplete)
      );
    }

    // Category filter
    if (filterOptions.category) {
      matches = matches && business.category === filterOptions.category;
    }

    // Date range filter
    if (filterOptions.dateRange.from) {
      const businessDate = new Date(business.created_at || '');
      const fromDate = filterOptions.dateRange.from;
      const toDate = filterOptions.dateRange.to || new Date();
      matches = matches && businessDate >= fromDate && businessDate <= toDate;
    }

    return matches;
  }).sort((a, b) => {
    const aValue = a[sortField]?.toString().toLowerCase() || '';
    const bValue = b[sortField]?.toString().toLowerCase() || '';
    return sortDirection === 'asc' 
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  }) || [];

  const handleSort = (field: keyof BusinessWithProfile) => {
    if (sortField === field) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  if (error) {
    return (
      <Card className="mt-6">
        <CardHeader className="pb-4">
          <CardTitle className="text-red-500">Error Loading Data</CardTitle>
          <CardDescription>
            There was an error loading the business profiles. Please try again later.
          </CardDescription>
        </CardHeader>
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle>Business Profiles</CardTitle>
          <CardDescription>
            Search and manage business profiles
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <BusinessTableFilters
              searchQuery={searchQuery}
              searchField={searchField}
              filterOptions={filterOptions}
              searchHistory={searchHistory}
              filteredBusinesses={filteredBusinesses}
              onSearchQueryChange={setSearchQuery}
              onSearchFieldChange={setSearchField}
              onFilterOptionsChange={setFilterOptions}
              onSearch={handleSearch}
              onHistoryItemClick={setSearchQuery}
              onExport={() => exportToCSV(filteredBusinesses)}
            />

            <BusinessTableContent
              businesses={filteredBusinesses}
              sortField={sortField}
              sortDirection={sortDirection}
              onSort={handleSort}
              onDelete={handleDeleteBusiness}
              getCompletionStatus={getCompletionStatus}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
