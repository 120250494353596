export interface Testimonial {
  id: number;
  name: string;
  role: string;
  business: string;
  location: string;
  content: string;
  rating: number;
  improvement: string;
}

export const testimonials: Testimonial[] = [
  {
    id: 1,
    name: "Sarah Johnson",
    role: "Owner",
    business: "The Cozy Corner Café",
    location: "Portland, OR",
    content: "Since partnering with Woobound Marketing, our café has seen a 150% increase in Google Maps visibility. Our weekend brunches are now fully booked weeks in advance!",
    rating: 5,
    improvement: "150% increase in Google Maps visibility"
  },
  {
    id: 2,
    name: "Michael Chen",
    role: "Director",
    business: "Pacific Dental Care",
    location: "San Francisco, CA",
    content: "Our practice has doubled its new patient acquisitions within just 6 months of implementing Woobound's local SEO strategy. The ROI has been remarkable.",
    rating: 5,
    improvement: "100% increase in new patients"
  },
  {
    id: 3,
    name: "Emily Rodriguez",
    role: "Manager",
    business: "Fitness First Gym",
    location: "Miami, FL",
    content: "The local SEO campaign by Woobound helped us become the top-ranked gym in our area. Our membership sign-ups have increased by 75% year-over-year.",
    rating: 5,
    improvement: "75% increase in memberships"
  },
  {
    id: 4,
    name: "David Smith",
    role: "Owner",
    business: "Smith's Auto Repair",
    location: "Austin, TX",
    content: "Thanks to Woobound, our shop's visibility on Google Maps has skyrocketed. We've seen a 200% increase in calls from potential customers.",
    rating: 5,
    improvement: "200% increase in customer inquiries"
  },
  {
    id: 5,
    name: "Jessica Lee",
    role: "Owner",
    business: "Lee's Bakery",
    location: "Seattle, WA",
    content: "Our sales have increased by 50% since we started working with Woobound. Their local SEO strategies are truly effective!",
    rating: 5,
    improvement: "50% increase in sales"
  },
  {
    id: 6,
    name: "Robert Brown",
    role: "Manager",
    business: "Brown's Landscaping",
    location: "Denver, CO",
    content: "Woobound's SEO services have transformed our business. We've gained numerous new clients and our online presence is stronger than ever.",
    rating: 5,
    improvement: "Significant increase in new clients"
  },
  {
    id: 7,
    name: "Linda Green",
    role: "Owner",
    business: "Green's Florist",
    location: "Chicago, IL",
    content: "Our flower shop has seen a 120% increase in online orders since partnering with Woobound. Their team knows what they're doing!",
    rating: 5,
    improvement: "120% increase in online orders"
  },
  {
    id: 8,
    name: "James Wilson",
    role: "Owner",
    business: "Wilson's Plumbing",
    location: "Phoenix, AZ",
    content: "The local SEO strategies implemented by Woobound have brought us more leads than we could have imagined. Highly recommend!",
    rating: 5,
    improvement: "Increased leads and customer inquiries"
  },
  {
    id: 9,
    name: "Patricia Taylor",
    role: "Owner",
    business: "Taylor's Pet Grooming",
    location: "Orlando, FL",
    content: "Our grooming business has flourished since we started with Woobound. We've gained a loyal customer base and our visibility is unmatched.",
    rating: 5,
    improvement: "Loyal customer base and increased visibility"
  },
  {
    id: 10,
    name: "Charles Martinez",
    role: "Owner",
    business: "Martinez Construction",
    location: "Las Vegas, NV",
    content: "Woobound's expertise in local SEO has helped us secure more contracts and grow our business significantly.",
    rating: 5,
    improvement: "Secured more contracts"
  },
  {
    id: 11,
    name: "Nancy Anderson",
    role: "Owner",
    business: "Anderson's Coffee Shop",
    location: "Boston, MA",
    content: "Our coffee shop's foot traffic has doubled since we started working with Woobound. Their strategies are effective and easy to implement.",
    rating: 5,
    improvement: "Doubled foot traffic"
  },
  {
    id: 12,
    name: "Daniel Thomas",
    role: "Owner",
    business: "Thomas's Fitness Center",
    location: "Atlanta, GA",
    content: "The results from Woobound's local SEO services have been incredible. Our membership numbers are at an all-time high!",
    rating: 5,
    improvement: "All-time high membership numbers"
  },
  {
    id: 13,
    name: "Laura Jackson",
    role: "Owner",
    business: "Jackson's Art Studio",
    location: "San Diego, CA",
    content: "Thanks to Woobound, our art studio is now the go-to place for local art classes. We've seen a 90% increase in sign-ups.",
    rating: 5,
    improvement: "90% increase in class sign-ups"
  },
  {
    id: 14,
    name: "Matthew White",
    role: "Owner",
    business: "White's Home Services",
    location: "Philadelphia, PA",
    content: "Our home services business has thrived since partnering with Woobound. Their local SEO strategies are top-notch!",
    rating: 5,
    improvement: "Thriving business with increased visibility"
  },
  {
    id: 15,
    name: "Sophia Harris",
    role: "Owner",
    business: "Harris's Boutique",
    location: "Dallas, TX",
    content: "Woobound has helped us reach a wider audience. Our sales have increased by 60% since we started.",
    rating: 5,
    improvement: "60% increase in sales"
  },
  {
    id: 16,
    name: "Christopher Clark",
    role: "Owner",
    business: "Clark's Auto Body",
    location: "Houston, TX",
    content: "The local SEO services from Woobound have been a game changer for our auto body shop. We've seen a 150% increase in inquiries.",
    rating: 5,
    improvement: "150% increase in inquiries"
  },
  {
    id: 17,
    name: "Megan Lewis",
    role: "Owner",
    business: "Lewis's Dance Studio",
    location: "Seattle, WA",
    content: "Our dance studio has become the most popular in the area thanks to Woobound's effective SEO strategies.",
    rating: 5,
    improvement: "Most popular dance studio in the area"
  },
  {
    id: 18,
    name: "Brian Young",
    role: "Owner",
    business: "Young's Landscaping",
    location: "Miami, FL",
    content: "Woobound's local SEO strategies have helped us dominate the landscaping market in our area. Highly recommend their services!",
    rating: 5,
    improvement: "Dominated the landscaping market"
  },
  {
    id: 19,
    name: "Emily King",
    role: "Owner",
    business: "King's Bakery",
    location: "New York, NY",
    content: "Our bakery's online orders have skyrocketed since we started working with Woobound. Their team is fantastic!",
    rating: 5,
    improvement: "Skyrocketed online orders"
  },
  {
    id: 20,
    name: "Kevin Scott",
    role: "Owner",
    business: "Scott's Plumbing",
    location: "Los Angeles, CA",
    content: "Thanks to Woobound, our plumbing business is thriving. We've seen a 200% increase in calls from new customers.",
    rating: 5,
    improvement: "200% increase in calls"
  },
  {
    id: 21,
    name: "Olivia Hall",
    role: "Owner",
    business: "Hall's Fitness Center",
    location: "Phoenix, AZ",
    content: "Our fitness center has become a local favorite, all thanks to Woobound's amazing SEO strategies.",
    rating: 5,
    improvement: "Became a local favorite"
  },
  {
    id: 22,
    name: "Jack Allen",
    role: "Owner",
    business: "Allen's Home Repair",
    location: "Chicago, IL",
    content: "Woobound's local SEO services have brought us more clients than we could have imagined. Highly recommend!",
    rating: 5,
    improvement: "More clients than ever"
  },
  {
    id: 23,
    name: "Ava Wright",
    role: "Owner",
    business: "Wright's Pet Grooming",
    location: "San Francisco, CA",
    content: "Our pet grooming business has seen a 100% increase in bookings since we started with Woobound. Their team is great!",
    rating: 5,
    improvement: "100% increase in bookings"
  },
  {
    id: 24,
    name: "Lucas Hill",
    role: "Owner",
    business: "Hill's Landscaping",
    location: "Dallas, TX",
    content: "Thanks to Woobound, our landscaping business is thriving. We've gained numerous new clients.",
    rating: 5,
    improvement: "Gained numerous new clients"
  },
  {
    id: 25,
    name: "Ella Green",
    role: "Owner",
    business: "Green's Coffee Shop",
    location: "Austin, TX",
    content: "Our coffee shop's visibility has increased dramatically since we partnered with Woobound. Highly recommend their services!",
    rating: 5,
    improvement: "Dramatic increase in visibility"
  },
];

// Subset of testimonials for the landing page slider
export const featuredTestimonials = testimonials.slice(0, 10);
