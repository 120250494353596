import { useState } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useDebounce } from '@/hooks/useDebounce';
import { useQuery } from '@tanstack/react-query';
import { PlacePrediction, BusinessDetails } from '@/components/onboarding/steps/business-search/types';

export const useBusinessSearch = () => {
  const [search, setSearch] = useState('');
  const [selectedPlaceId, setSelectedPlaceId] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);

  const { data: predictions = [], isLoading: isSearching } = useQuery({
    queryKey: ['placePredictions', debouncedSearch],
    queryFn: async (): Promise<PlacePrediction[]> => {
      if (!debouncedSearch || debouncedSearch.length < 3) return [];
      
      try {
        const { data, error } = await supabase.functions.invoke('place-search', {
          body: { query: debouncedSearch }
        });
        
        if (error) {
          console.error('Error fetching predictions:', error);
          return [];
        }
        
        return data?.predictions || [];
      } catch (error) {
        console.error('Error in place search:', error);
        return [];
      }
    },
    enabled: debouncedSearch.length >= 3,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  const { data: placeDetails, isLoading: isLoadingDetails } = useQuery({
    queryKey: ['placeDetails', selectedPlaceId],
    queryFn: async (): Promise<BusinessDetails | null> => {
      if (!selectedPlaceId) return null;

      try {
        const { data, error } = await supabase.functions.invoke('place-search', {
          body: { placeId: selectedPlaceId }
        });

        if (error) {
          console.error('Error fetching place details:', error);
          return null;
        }
        
        return data || null;
      } catch (error) {
        console.error('Error in place details:', error);
        return null;
      }
    },
    enabled: !!selectedPlaceId,
  });

  return {
    search,
    setSearch,
    predictions,
    isSearching,
    selectedPlaceId,
    setSelectedPlaceId,
    placeDetails,
    isLoadingDetails
  };
};