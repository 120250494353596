export const dashboard = {
  title: 'Panel de Control',
  welcome: '¡Bienvenido de nuevo! Aquí está tu resumen de SEO.',
  noTasks: 'No hay tareas disponibles en este momento. ¡Vuelve más tarde!',
  onboardingProgress: {
    title: 'Progreso de Tareas',
    subtitle: 'Sigue tu progreso',
    tasks: {
      selectPackage: 'Seleccionar paquete',
      setupAccount: 'Configurar tu cuenta',
      grantAccess: 'Conceder acceso a Google',
      connectNow: 'Conectar Ahora',
    },
    status: {
      completed: 'Completado',
      in_progress: 'En Progreso',
      not_started: 'No Iniciado',
      pending: 'Pendiente',
    }
  },
  nextSteps: {
    title: 'Próximos Pasos',
    subtitle: 'Recursos para ayudarte a comenzar',
    articles: {
      readMore: 'Leer Más',
    }
  },
  metrics: {
    averagePosition: {
      title: 'Posición Promedio',
      trend: '2.1%'
    },
    keywordsTracked: {
      title: 'Palabras Clave Rastreadas',
      trend: '12 nuevas'
    },
    organicTraffic: {
      title: 'Tráfico Orgánico',
      trend: '18.2%'
    },
    backlinks: {
      title: 'Backlinks',
      trend: '4 nuevos'
    }
  }
};