export const settings = {
  title: "Configuración",
  subtitle: "Administra la configuración y preferencias de tu cuenta",
  personalInfo: {
    title: "Información Personal",
    subtitle: "Actualiza tus datos personales",
    firstName: "Nombre",
    lastName: "Apellido",
    email: "Correo Electrónico",
    emailHelp: "Tu dirección de correo electrónico está asociada a tu cuenta y no se puede cambiar.",
    phoneNumber: "Número de Teléfono",
    role: "Rol",
    saveChanges: "Guardar Cambios",
    saving: "Guardando...",
    success: "Configuración actualizada exitosamente",
    error: "Error al actualizar la configuración"
  },
  security: {
    title: "Seguridad",
    subtitle: "Administra tu contraseña y configuración de seguridad",
    resetPassword: "Restablecer Contraseña",
    resetPasswordSuccess: "Correo de restablecimiento de contraseña enviado",
    resetPasswordError: "Error al enviar el correo de restablecimiento de contraseña"
  }
};