import { useState } from "react";
import { Button } from "@/components/ui/button";
import { ExternalLink } from "lucide-react";

interface GoogleConnectStepProps {
  onComplete: (connected: boolean) => void;
}

export const GoogleConnectStep = ({ onComplete }: GoogleConnectStepProps) => {
  const [isConnected, setIsConnected] = useState(false);

  const handleGoogleConnect = () => {
    window.open('https://woobound.digitalsero.app/clients/onboarding/c50a97dd-5503-4c9b-89a3-1f8c795a0b95', '_blank');
    setIsConnected(true);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold text-[#333333] mb-4">Connect Google Business Profile</h2>
      
      <div className="space-y-4">
        <Button 
          onClick={handleGoogleConnect}
          className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90 flex items-center justify-center"
        >
          Connect Google Business Profile
          <ExternalLink className="ml-2 h-4 w-4" />
        </Button>

        {isConnected && (
          <Button 
            onClick={() => onComplete(true)}
            className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90"
          >
            Done
          </Button>
        )}

        <Button 
          variant="outline"
          onClick={() => onComplete(false)}
          className="w-full"
        >
          Skip for now
        </Button>
      </div>
    </div>
  );
};