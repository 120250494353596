import { Navigate } from "react-router-dom";
import { ReactNode } from "react";

interface ProtectedRouteProps {
  children: ReactNode;
  session: any;
  profile?: any;
  requireAdmin?: boolean;
  requirePartner?: boolean;
}

export const ProtectedRoute = ({ 
  children, 
  session, 
  profile, 
  requireAdmin = false,
  requirePartner = false 
}: ProtectedRouteProps) => {
  if (!session) {
    return <Navigate to="/login" />;
  }

  if (requireAdmin && !profile?.is_admin) {
    return <Navigate to="/dashboard" />;
  }

  if (requirePartner && !(profile?.is_partner || profile?.is_admin)) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};