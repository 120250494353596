import { AlertCircle, CheckCircle2 } from "lucide-react";

interface DisplayFieldProps {
  value: string | string[] | null;
}

export const DisplayField = ({ value }: DisplayFieldProps) => {
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return (
      <span className="text-red-500 flex items-center gap-2">
        <AlertCircle className="h-4 w-4" />
        Needs to be updated
      </span>
    );
  }

  const displayValue = Array.isArray(value) ? value.join(", ") : value;

  return (
    <span className="flex items-center gap-2">
      <CheckCircle2 className="h-4 w-4 text-green-500" />
      {displayValue}
    </span>
  );
};