import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { BusinessWithProfile } from "../types";

interface EditableFieldProps {
  value: string | string[] | null;
  fieldName: keyof BusinessWithProfile;
  onChange: (fieldName: keyof BusinessWithProfile, value: any) => void;
}

export const EditableField = ({ value, fieldName, onChange }: EditableFieldProps) => {
  if (Array.isArray(value)) {
    return (
      <Input
        value={value.join(", ")}
        onChange={(e) => onChange(fieldName, e.target.value.split(",").map(item => item.trim()))}
      />
    );
  }

  if (fieldName === "long_description" || fieldName === "short_description" || fieldName === "admin_notes") {
    return (
      <Textarea
        value={value || ""}
        onChange={(e) => onChange(fieldName, e.target.value)}
      />
    );
  }

  return (
    <Input
      value={value || ""}
      onChange={(e) => onChange(fieldName, e.target.value)}
    />
  );
};