import { AppSidebar } from "@/components/AppSidebar";
import { SidebarProvider } from "@/components/ui/sidebar";
import { BusinessInfoForm } from "@/components/business/BusinessInfoForm";
import { useTranslation } from "react-i18next";

const BusinessProfile = () => {
  const { t } = useTranslation();

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-gray-50">
        <AppSidebar />
        <div className="flex-1 p-8">
          <div className="max-w-6xl mx-auto space-y-8">
            <div className="mb-8">
              <h1 className="text-2xl font-semibold text-gray-900">{t('business.title')}</h1>
              <p className="text-sm text-gray-500">{t('business.subtitle')}</p>
            </div>
            <BusinessInfoForm />
          </div>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default BusinessProfile;