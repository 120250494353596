import { useState } from "react";
import { TableCell, TableRow } from "@/components/ui/table";
import { BusinessWithProfile, CompletionStatus } from "./types";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { EditableField } from "./business-row/EditableField";
import { DisplayField } from "./business-row/DisplayField";
import { RowActions } from "./business-row/RowActions";
import { CheckCircle2, AlertCircle } from "lucide-react";

interface BusinessTableRowProps {
  business: BusinessWithProfile;
  getCompletionStatus: (business: BusinessWithProfile) => CompletionStatus;
  onDelete: () => void;
}

export const BusinessTableRow = ({ business, getCompletionStatus, onDelete }: BusinessTableRowProps) => {
  const { toast } = useToast();
  const status = getCompletionStatus(business);
  const [isEditing, setIsEditing] = useState(false);
  const [editedBusiness, setEditedBusiness] = useState<BusinessWithProfile>(business);

  const handleSave = async () => {
    try {
      const { error } = await supabase
        .from('business_profiles')
        .update({
          business_name: editedBusiness.business_name,
          business_email: editedBusiness.business_email,
          phone_number: editedBusiness.phone_number,
          street_address: editedBusiness.street_address,
          city: editedBusiness.city,
          state: editedBusiness.state,
          zip_code: editedBusiness.zip_code,
          category: editedBusiness.category,
          website_url: editedBusiness.website_url,
          facebook_url: editedBusiness.facebook_url,
          instagram_url: editedBusiness.instagram_url,
          opening_date: editedBusiness.opening_date,
          opening_hours: editedBusiness.opening_hours,
          short_description: editedBusiness.short_description,
          long_description: editedBusiness.long_description,
          business_services: editedBusiness.business_services,
          desired_keywords: editedBusiness.desired_keywords,
          payment_methods: editedBusiness.payment_methods,
          promotions: editedBusiness.promotions,
          seo_campaign_goal: editedBusiness.seo_campaign_goal,
          admin_notes: editedBusiness.admin_notes,
        })
        .eq('id', business.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Business profile updated successfully",
      });
      setIsEditing(false);
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const handleInputChange = (fieldName: keyof BusinessWithProfile, value: any) => {
    setEditedBusiness(prev => ({
      ...prev,
      [fieldName]: value
    }));
  };

  const fields = [
    ['Business Name', editedBusiness.business_name, 'business_name'],
    ['Owner', editedBusiness.profiles ? `${editedBusiness.profiles.first_name || ''} ${editedBusiness.profiles.last_name || ''}`.trim() : null, null],
    ['Business Email', editedBusiness.business_email, 'business_email'],
    ['Phone', editedBusiness.phone_number, 'phone_number'],
    ['Street Address', editedBusiness.street_address, 'street_address'],
    ['City', editedBusiness.city, 'city'],
    ['State', editedBusiness.state, 'state'],
    ['ZIP Code', editedBusiness.zip_code, 'zip_code'],
    ['Category', editedBusiness.category, 'category'],
    ['Website', editedBusiness.website_url, 'website_url'],
    ['Facebook', editedBusiness.facebook_url, 'facebook_url'],
    ['Instagram', editedBusiness.instagram_url, 'instagram_url'],
    ['Opening Date', editedBusiness.opening_date, 'opening_date'],
    ['Opening Hours', editedBusiness.opening_hours, 'opening_hours'],
    ['Short Description', editedBusiness.short_description, 'short_description'],
    ['Long Description', editedBusiness.long_description, 'long_description'],
    ['Services', editedBusiness.business_services, 'business_services'],
    ['Keywords', editedBusiness.desired_keywords, 'desired_keywords'],
    ['Payment Methods', editedBusiness.payment_methods, 'payment_methods'],
    ['Promotions', editedBusiness.promotions, 'promotions'],
    ['SEO Goal', editedBusiness.seo_campaign_goal, 'seo_campaign_goal'],
    ['Admin Notes', editedBusiness.admin_notes, 'admin_notes'],
  ] as const;

  return (
    <>
      {fields.map(([label, value, fieldName]) => (
        <TableRow key={`${business.id}-${label}`}>
          <TableCell className="font-medium w-48">{label}</TableCell>
          <TableCell>
            {fieldName ? (
              isEditing ? (
                <EditableField
                  value={value}
                  fieldName={fieldName}
                  onChange={handleInputChange}
                />
              ) : (
                <DisplayField value={value} />
              )
            ) : (
              value
            )}
          </TableCell>
        </TableRow>
      ))}
      <TableRow>
        <TableCell className="font-medium w-48">Status</TableCell>
        <TableCell>
          <div className="flex items-center gap-2">
            {status.completed === status.total ? (
              <CheckCircle2 className="h-4 w-4 text-green-500" />
            ) : (
              <AlertCircle className="h-4 w-4 text-amber-500" />
            )}
            {`${status.completed}/${status.total} fields complete`}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="font-medium w-48">Actions</TableCell>
        <TableCell>
          <RowActions
            isEditing={isEditing}
            onSave={handleSave}
            onCancel={() => {
              setIsEditing(false);
              setEditedBusiness(business);
            }}
            onEdit={() => setIsEditing(true)}
            onDelete={onDelete}
          />
        </TableCell>
      </TableRow>
    </>
  );
};