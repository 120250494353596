import { useTranslation } from "react-i18next";

export const HowItWorks = () => {
  const { t } = useTranslation();

  const steps = [
    { key: 'step1', step: 1 },
    { key: 'step2', step: 2 },
    { key: 'step3', step: 3 },
  ];

  return (
    <section className="py-32 px-4 bg-white">
      <div className="container mx-auto max-w-6xl">
        <div className="text-center max-w-3xl mx-auto mb-20">
          <h2 className="text-4xl font-bold mb-6 text-[#221F26]">{t('landing.howItWorks.title')}</h2>
          <p className="text-xl text-[#403E43]/80 leading-relaxed">
            {t('landing.howItWorks.subtitle')}
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-12">
          {steps.map((step) => (
            <div key={step.key} className="text-center relative group">
              <div className="w-16 h-16 bg-primary text-white rounded-2xl flex items-center justify-center mx-auto mb-6 text-xl font-bold shadow-lg group-hover:scale-110 transition-transform">
                {step.step}
              </div>
              <h3 className="text-2xl font-semibold mb-4 text-[#221F26]">
                {t(`landing.howItWorks.steps.${step.key}.title`)}
              </h3>
              <p className="text-[#403E43]/80 text-lg leading-relaxed">
                {t(`landing.howItWorks.steps.${step.key}.description`)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};