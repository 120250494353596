import { Checkbox } from "@/components/ui/checkbox";

interface TermsAgreementProps {
  termsAgreed: boolean;
  onTermsChange: (checked: boolean) => void;
  onOpenModal: (title: string, url: string) => void;
}

export const TermsAgreement = ({ termsAgreed, onTermsChange, onOpenModal }: TermsAgreementProps) => {
  return (
    <div className="flex items-start space-x-2">
      <Checkbox
        id="terms"
        checked={termsAgreed}
        onCheckedChange={(checked) => onTermsChange(checked as boolean)}
        aria-required="true"
      />
      <label htmlFor="terms" className="text-sm">
        I agree to the{" "}
        <button
          type="button"
          onClick={() => onOpenModal("SEO Agreement", "/terms/seo-agreement.html")}
          className="text-blue-600 hover:underline"
        >
          SEO Agreement
        </button>
        ,{" "}
        <button
          type="button"
          onClick={() => onOpenModal("Terms and Conditions", "/terms/terms-conditions.html")}
          className="text-blue-600 hover:underline"
        >
          Terms and Conditions
        </button>
        , and{" "}
        <button
          type="button"
          onClick={() => onOpenModal("Privacy Policy", "/terms/privacy-policy.html")}
          className="text-blue-600 hover:underline"
        >
          Privacy Policy
        </button>
      </label>
    </div>
  );
};