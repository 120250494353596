import { Search, Filter } from "lucide-react";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { SearchField, FilterOptions } from "./types";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

interface SearchBarProps {
  searchQuery: string;
  searchField: SearchField;
  filterOptions: FilterOptions;
  onSearchQueryChange: (value: string) => void;
  onSearchFieldChange: (value: SearchField) => void;
  onFilterOptionsChange: (options: FilterOptions) => void;
  onSearch: () => void;
}

export const SearchBar = ({
  searchQuery,
  searchField,
  filterOptions,
  onSearchQueryChange,
  onSearchFieldChange,
  onFilterOptionsChange,
  onSearch,
}: SearchBarProps) => {
  return (
    <div className="space-y-4">
      <div className="flex flex-col md:flex-row md:items-center gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
          <Input
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => onSearchQueryChange(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && onSearch()}
            className="pl-10"
          />
        </div>
        <Select
          value={searchField}
          onValueChange={(value) => onSearchFieldChange(value as SearchField)}
        >
          <SelectTrigger className="w-full md:w-[200px]">
            <SelectValue placeholder="Search by..." />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="business_name">Business Name</SelectItem>
            <SelectItem value="phone_number">Phone Number</SelectItem>
            <SelectItem value="street_address">Street Address</SelectItem>
            <SelectItem value="zip_code">Zip Code</SelectItem>
            <SelectItem value="category">Category</SelectItem>
            <SelectItem value="created_at">Date Created</SelectItem>
          </SelectContent>
        </Select>
        <Button onClick={onSearch} className="w-full md:w-auto">
          Search
        </Button>
      </div>

      <div className="flex flex-col md:flex-row gap-4">
        <Select
          value={filterOptions.completionStatus}
          onValueChange={(value: 'all' | 'complete' | 'incomplete') => 
            onFilterOptionsChange({ ...filterOptions, completionStatus: value })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Completion Status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Profiles</SelectItem>
            <SelectItem value="complete">Complete Profiles</SelectItem>
            <SelectItem value="incomplete">Incomplete Profiles</SelectItem>
          </SelectContent>
        </Select>

        <Select
          value={filterOptions.category}
          onValueChange={(value) => 
            onFilterOptionsChange({ ...filterOptions, category: value || 'all' })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Business Category" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Categories</SelectItem>
            <SelectItem value="restaurant">Restaurant</SelectItem>
            <SelectItem value="retail">Retail</SelectItem>
            <SelectItem value="service">Service</SelectItem>
          </SelectContent>
        </Select>

        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline">
              <Filter className="h-4 w-4 mr-2" />
              Date Range
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="range"
              selected={{
                from: filterOptions.dateRange.from || undefined,
                to: filterOptions.dateRange.to || undefined,
              }}
              onSelect={(range) => 
                onFilterOptionsChange({
                  ...filterOptions,
                  dateRange: {
                    from: range?.from || null,
                    to: range?.to || null,
                  },
                })
              }
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};