import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { FileText } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";

interface PartnerResource {
  id: string;
  title: string;
  description: string | null;
  resource_url: string;
  resource_type: string;
}

export const PartnerResources = () => {
  const { data: resources, isLoading } = useQuery({
    queryKey: ['partner_resources'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('partner_resources')
        .select('*');
      if (error) throw error;
      return data as PartnerResource[];
    },
  });

  if (isLoading) {
    return (
      <section>
        <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
          <FileText className="h-5 w-5 text-[#F97316]" />
          Resources
        </h2>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {Array(3).fill(0).map((_, i) => (
            <Card key={i} className="p-6 animate-pulse">
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
              <div className="h-3 bg-gray-200 rounded w-1/2"></div>
            </Card>
          ))}
        </div>
      </section>
    );
  }

  return (
    <section>
      <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
        <FileText className="h-5 w-5 text-[#F97316]" />
        Resources
      </h2>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {resources?.map((resource) => (
          <Card key={resource.id} className="p-6">
            <h3 className="font-semibold text-gray-900 mb-2">{resource.title}</h3>
            <p className="text-sm text-gray-500 mb-4">{resource.description}</p>
            <Button asChild variant="outline">
              <a href={resource.resource_url} target="_blank" rel="noopener noreferrer">
                Download Resource
              </a>
            </Button>
          </Card>
        ))}
      </div>
    </section>
  );
};