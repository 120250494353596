import { dashboard } from "./es/dashboard";
import { landing } from "./es/landing";
import { settings } from "./es/settings";

export const esTranslations = {
  common: {
    dashboard: "Panel de Control",
    resources: "Recursos",
    videos: "Videos",
    myAccount: "Mi Cuenta",
    businessProfile: "Perfil de Negocio",
    integrations: "Integraciones",
    billing: "Facturación",
    settings: "Configuración",
    support: "Soporte",
    adminPanel: "Panel de Administración",
    partner: "Portal de Socios",
    logout: "Cerrar Sesión"
  },
  dashboard,
  landing,
  settings,
  resources: {
    title: "Recursos de Aprendizaje",
    subtitle: "Explora nuestro contenido seleccionado para ayudarte a tener éxito con SEO",
    readMore: "Leer Más",
    startReading: "Empezar a Leer",
    readAgain: "Leer de Nuevo",
    noResources: "No hay recursos disponibles en este momento."
  },
  videos: {
    title: "Recursos en Video",
    subtitle: "Mira nuestro contenido en video seleccionado para mejorar tu conocimiento de SEO"
  },
  support: {
    title: "Soporte",
    subtitle: "Obtén ayuda con tu cuenta"
  }
};