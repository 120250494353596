import { dashboard } from "./en/dashboard";
import { landing } from "./en/landing";
import { settings } from "./en/settings";

export const enTranslations = {
  common: {
    dashboard: "Dashboard",
    resources: "Resources",
    videos: "Videos",
    myAccount: "My Account",
    businessProfile: "Business Profile",
    integrations: "Integrations",
    billing: "Billing",
    settings: "Settings",
    support: "Support",
    adminPanel: "Admin Panel",
    partner: "Partner Portal",
    logout: "Logout"
  },
  dashboard,
  landing,
  settings,
  resources: {
    title: "Learning Resources",
    subtitle: "Explore our curated content to help you succeed with SEO",
    readMore: "Read More",
    startReading: "Start Reading",
    readAgain: "Read Again",
    noResources: "No resources available at this time."
  },
  videos: {
    title: "Video Resources",
    subtitle: "Watch our curated video content to enhance your SEO knowledge"
  },
  support: {
    title: "Support",
    subtitle: "Get help with your account"
  }
};