import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { CombinedFormData } from "../types";

interface PersonalInfoFieldsProps {
  formData: CombinedFormData;
  isSubmitting: boolean;
  onChange: (field: keyof CombinedFormData, value: string) => void;
}

export const PersonalInfoFields = ({ formData, isSubmitting, onChange }: PersonalInfoFieldsProps) => {
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Personal Information</h2>
      <div className="grid md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="email">Email address <span className="text-red-500">*</span></Label>
          <Input
            id="email"
            type="email"
            value={formData.email}
            onChange={(e) => onChange('email', e.target.value)}
            placeholder="Enter your email"
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="password">Password <span className="text-red-500">*</span></Label>
          <Input
            id="password"
            type="password"
            value={formData.password}
            onChange={(e) => onChange('password', e.target.value)}
            placeholder="Create a password"
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="firstName">First name <span className="text-red-500">*</span></Label>
          <Input
            id="firstName"
            type="text"
            value={formData.firstName}
            onChange={(e) => onChange('firstName', e.target.value)}
            placeholder="Enter your first name"
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="lastName">Last name <span className="text-red-500">*</span></Label>
          <Input
            id="lastName"
            type="text"
            value={formData.lastName}
            onChange={(e) => onChange('lastName', e.target.value)}
            placeholder="Enter your last name"
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="phoneNumber">Mobile Phone (optional for texting)</Label>
          <Input
            id="phoneNumber"
            type="tel"
            value={formData.phoneNumber}
            onChange={(e) => onChange('phoneNumber', e.target.value)}
            placeholder="Enter your mobile phone number"
            disabled={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};