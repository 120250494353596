import { AppSidebar } from "@/components/AppSidebar";
import { SidebarProvider } from "@/components/ui/sidebar";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useTranslation } from 'react-i18next';

const Integrations = () => {
  const { t } = useTranslation();

  const handleGoogleConnect = () => {
    window.open('https://woobound.digitalsero.app/clients/onboarding/c50a97dd-5503-4c9b-89a3-1f8c795a0b95', '_blank');
  };

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-gray-50">
        <AppSidebar />
        <div className="flex-1 p-8">
          <div className="max-w-6xl mx-auto space-y-8">
            <div className="mb-8">
              <h1 className="text-2xl font-semibold text-gray-900">{t('integrations.title')}</h1>
              <p className="text-sm text-gray-500">{t('integrations.subtitle')}</p>
            </div>

            {/* Google Apps Section */}
            <Card className="p-6 bg-white shadow-sm hover:shadow-md transition-shadow">
              <div className="flex items-center justify-between mb-4">
                <div>
                  <h2 className="text-xl font-semibold text-gray-900">{t('integrations.google.title')}</h2>
                  <p className="text-sm text-gray-500 mt-1">{t('integrations.google.subtitle')}</p>
                </div>
                <img 
                  src="/lovable-uploads/4537cee8-1bfb-4e9c-a1e4-e0e8544fc051.png" 
                  alt="Google"
                  className="h-8 object-contain"
                />
              </div>
              <div className="space-y-4">
                <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div>
                    <h3 className="font-medium text-gray-900">{t('integrations.google.allServices')}</h3>
                    <p className="text-sm text-gray-500">
                      Connect your Google Business Profile, Analytics, and Search Console to optimize your online presence
                    </p>
                  </div>
                  <Button 
                    variant="outline" 
                    className="hover:bg-[#4285F4] hover:text-white"
                    onClick={handleGoogleConnect}
                  >
                    {t('integrations.google.connect')}
                  </Button>
                </div>
              </div>
            </Card>

            {/* Facebook Section */}
            <Card className="p-6 bg-white shadow-sm hover:shadow-md transition-shadow opacity-75">
              <div className="flex items-center justify-between mb-4">
                <div>
                  <h2 className="text-xl font-semibold text-gray-900">{t('integrations.facebook.title')}</h2>
                  <p className="text-sm text-gray-500 mt-1">{t('integrations.facebook.subtitle')}</p>
                </div>
                <img 
                  src="/lovable-uploads/22f65ef8-d7da-47ff-ae0a-3fd70a2e4e2f.png" 
                  alt="Facebook"
                  className="h-8 w-8 object-contain"
                />
              </div>
              <div className="p-4 bg-gray-50 rounded-lg">
                <p className="text-center text-gray-500 font-medium">{t('integrations.facebook.comingSoon')}</p>
              </div>
            </Card>

            {/* TikTok Section */}
            <Card className="p-6 bg-white shadow-sm hover:shadow-md transition-shadow opacity-75">
              <div className="flex items-center justify-between mb-4">
                <div>
                  <h2 className="text-xl font-semibold text-gray-900">{t('integrations.tiktok.title')}</h2>
                  <p className="text-sm text-gray-500 mt-1">{t('integrations.tiktok.subtitle')}</p>
                </div>
                <img 
                  src="/lovable-uploads/b865f045-e72c-4da2-9d97-92f0e42bdacb.png" 
                  alt="TikTok"
                  className="h-8 w-8 object-contain"
                />
              </div>
              <div className="p-4 bg-gray-50 rounded-lg">
                <p className="text-center text-gray-500 font-medium">{t('integrations.tiktok.comingSoon')}</p>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default Integrations;