import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster";
import ArticlePage from "@/pages/ArticlePage";
import Resources from "@/pages/Resources";
import AdminPanel from "@/pages/AdminPanel";
import Dashboard from "@/pages/Dashboard";
import Landing from "@/pages/Landing";
import MyAccount from "@/pages/MyAccount";
import BusinessProfile from "@/pages/BusinessProfile";
import Integrations from "@/pages/Integrations";
import Billing from "@/pages/Billing";
import Settings from "@/pages/Settings";
import Support from "@/pages/Support";
import Login from "@/pages/Login";
import ChatSignupPage from "@/pages/ChatSignupPage";
import Videos from "@/pages/Videos";
import Partner from "@/pages/Partner";
import { SessionProvider } from "@/components/routing/SessionProvider";
import { ProtectedRoute } from "@/components/routing/ProtectedRoute";

function App() {
  return (
    <Router>
      <SessionProvider>
        {({ session, profile, loading }) => {
          if (loading) {
            return <div>Loading...</div>;
          }

          return (
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<Landing />} />
              <Route 
                path="/login" 
                element={
                  session ? (
                    profile?.is_admin ? (
                      <Navigate to="/admin" />
                    ) : (
                      <Navigate to="/dashboard" />
                    )
                  ) : (
                    <Login />
                  )
                } 
              />
              <Route path="/signup/:packageType" element={<ChatSignupPage />} />
              <Route path="/resources" element={<Resources />} />

              {/* Protected routes */}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute session={session}>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/articles/:articleId"
                element={
                  <ProtectedRoute session={session}>
                    <ArticlePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-account"
                element={
                  <ProtectedRoute session={session}>
                    <MyAccount />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/business-profile"
                element={
                  <ProtectedRoute session={session}>
                    <BusinessProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations"
                element={
                  <ProtectedRoute session={session}>
                    <Integrations />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/billing"
                element={
                  <ProtectedRoute session={session}>
                    <Billing />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute session={session}>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/support"
                element={
                  <ProtectedRoute session={session}>
                    <Support />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/videos"
                element={
                  <ProtectedRoute session={session}>
                    <Videos />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/partner"
                element={
                  <ProtectedRoute session={session} requirePartner>
                    <Partner />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute session={session} requireAdmin>
                    <AdminPanel />
                  </ProtectedRoute>
                }
              />

              {/* Catch all route */}
              <Route
                path="*"
                element={
                  session ? (
                    profile?.is_admin ? (
                      <Navigate to="/admin" />
                    ) : (
                      <Navigate to="/dashboard" />
                    )
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
            </Routes>
          );
        }}
      </SessionProvider>
      <Toaster />
    </Router>
  );
}

export default App;