export const landing = {
  hero: {
    title: "Transform Your Local Presence with Expert SEO",
    subtitle: "Dominate local search results and attract more customers to your business with our data-driven SEO strategies.",
    cta: "Start Your Journey",
  },
  features: {
    title: "Comprehensive SEO Solutions",
    subtitle: "Everything you need to boost your local visibility and attract more customers to your business.",
    items: {
      localSeo: {
        title: "Local SEO Optimization",
        description: "Dominate local search results and attract nearby customers",
      },
      analytics: {
        title: "Performance Analytics",
        description: "Track your progress with detailed insights and reports",
      },
      dashboard: {
        title: "Business Dashboard",
        description: "Manage your SEO campaign from one central location",
      },
      multiLanguage: {
        title: "Multi-language Support",
        description: "Full platform, email, and chat support in multiple languages",
      },
      experience: {
        title: "12 Years Experience",
        description: "Over a decade of proven SEO expertise and success",
      },
      roi: {
        title: "ROI Focused",
        description: "Strategies designed to maximize your return on investment",
      },
    },
    cta: "Start Your SEO Journey",
  },
  howItWorks: {
    title: "How It Works",
    subtitle: "Get started with our simple three-step process",
    steps: {
      step1: {
        title: "Select Your Package",
        description: "Choose the SEO package that best fits your business needs and goals",
      },
      step2: {
        title: "Quick Onboarding",
        description: "Complete our streamlined onboarding process with expert guidance",
      },
      step3: {
        title: "Watch Your Business Grow",
        description: "Monitor your progress as your online presence and customer base expand",
      },
    },
  },
  testimonials: {
    title: "Client Success Stories",
    subtitle: "See how we've helped businesses like yours achieve remarkable growth",
    items: {
      restaurant: {
        content: "The Maps SEO package helped us increase our local visibility significantly. Our restaurant bookings have increased by 40%!",
        name: "Sarah Johnson",
        role: "Restaurant Owner",
      },
      retail: {
        content: "Comprehensive SEO solution that delivered real results. Our online presence has never been stronger.",
        name: "Mike Thompson",
        role: "Retail Store Manager",
      },
      service: {
        content: "Great service and amazing results. Our Google Maps rankings improved within weeks!",
        name: "Lisa Chen",
        role: "Service Business Owner",
      },
    },
  },
  pricing: {
    title: "Our Local SEO Plans",
    mapsSeo: {
      title: "Maps SEO",
      price: "$598",
      description: "Get discovered by customers near you with a laser focus on Google Maps.",
      features: [
        "Google Maps Optimization",
        "Local Citations Management",
        "5-Mile Radius Optimization",
      ],
      bestFor: "Best for: Businesses looking to drive local foot traffic and capture nearby customers.",
      cta: "Get Started",
    },
    mapsWebsiteSeo: {
      title: "Maps + Website SEO",
      price: "$998",
      description: "Expand your visibility beyond Maps with a blend of local and website SEO strategies.",
      features: [
        "Everything in Maps SEO",
        "Basic Website Optimization",
        "Technical SEO (1 page/month)",
        "1 City Targeting",
      ],
      bestFor: "Best for: Businesses wanting to capture both local and search engine traffic.",
      cta: "Get Started",
    },
    customSeo: {
      title: "Custom SEO",
      price: "Prices vary",
      description: "Customized solutions for businesses in competitive industries or with unique needs.",
      features: [
        "SEO for Competitive Verticals",
        "Multi-City Targeting",
        "Fully customized strategies",
        "Monthly Strategy Calls",
      ],
      bestFor: "Best for: Businesses targeting multiple locations or requiring advanced SEO strategies.",
      cta: "Contact Us",
    },
  },
  footer: {
    company: {
      title: "Company",
      about: "About Us",
      contact: "Contact",
      careers: "Careers",
    },
    resources: {
      title: "Resources",
      blog: "Blog",
      caseStudies: "Case Studies",
      faqs: "FAQs",
    },
    legal: {
      title: "Legal",
      privacy: "Privacy Policy",
      terms: "Terms of Service",
      cookies: "Cookie Policy",
    },
    contact: {
      title: "Get in Touch",
      description: "Need help with your SEO strategy? Our experts are here to help.",
      cta: "Contact Support",
    },
    copyright: "© 2024 SEO Client Portal. All rights reserved.",
  },
  nav: {
    login: "Login",
    signup: "Sign Up",
    caseStudies: "Case Studies",
    pricing: "Pricing",
  },
};