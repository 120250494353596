import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { checkEmailExists } from "@/utils/auth";
import { Link } from "react-router-dom";
import { PersonalInfoFields } from "./combined-info/PersonalInfoFields";
import { BusinessInfoFields } from "./combined-info/BusinessInfoFields";
import { CombinedFormData } from "./types";

interface CombinedInfoStepProps {
  onComplete: (data: CombinedFormData) => void;
}

export const CombinedInfoStep = ({ onComplete }: CombinedInfoStepProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<CombinedFormData>({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phoneNumber: "",
    businessPhoneNumber: "",
    businessName: "",
    businessCategory: "",
    websiteUrl: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
  });
  
  const { toast } = useToast();

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFieldChange = (field: keyof CombinedFormData, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate required fields
    const requiredFields = ['email', 'firstName', 'lastName', 'password', 'businessName', 'businessCategory', 'streetAddress', 'city', 'state', 'zipCode'];
    const missingFields = requiredFields.filter(field => !formData[field as keyof CombinedFormData]);
    
    if (missingFields.length > 0) {
      toast({
        title: "Missing Information",
        description: "Please fill in all required fields.",
        variant: "destructive",
      });
      return;
    }

    if (!validateEmail(formData.email)) {
      toast({
        title: "Invalid Email",
        description: "Please enter a valid email address.",
        variant: "destructive",
      });
      return;
    }

    if (formData.password.length < 6) {
      toast({
        title: "Invalid Password",
        description: "Password must be at least 6 characters long.",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      // Check if email exists
      const emailExists = await checkEmailExists(formData.email);
      
      if (emailExists) {
        toast({
          title: "Account Already Exists",
          description: (
            <div>
              This email is already registered. Please{" "}
              <Link to="/login" className="underline font-medium">
                log in
              </Link>{" "}
              instead.
            </div>
          ),
          variant: "destructive",
        });
        return;
      }

      // Send data to webhook
      try {
        await fetch('https://hooks.zapier.com/hooks/catch/2653367/2swj4fj/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            personal_info: {
              email: formData.email,
              first_name: formData.firstName,
              last_name: formData.lastName,
              phone: formData.phoneNumber,
            },
            business_info: {
              business_name: formData.businessName,
              category: formData.businessCategory,
              website_url: formData.websiteUrl,
              street_address: formData.streetAddress,
              city: formData.city,
              state: formData.state,
              zip_code: formData.zipCode,
            },
            metadata: {
              signup_date: new Date().toISOString(),
              source: 'web',
            }
          })
        });
      } catch (error) {
        console.error('Error sending data to webhook:', error);
      }

      await onComplete(formData);
    } catch (error: any) {
      console.error('Error in signup:', error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <PersonalInfoFields
        formData={formData}
        isSubmitting={isSubmitting}
        onChange={handleFieldChange}
      />
      
      <BusinessInfoFields
        formData={formData}
        isSubmitting={isSubmitting}
        onChange={handleFieldChange}
      />

      <Button 
        type="submit" 
        className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90"
        disabled={isSubmitting}
      >
        {isSubmitting ? "Creating Account..." : "Continue"}
      </Button>

      <p className="text-center text-sm text-gray-500">
        Already have an account?{" "}
        <Link to="/login" className="text-[#e0933c] hover:underline">
          Log in
        </Link>
      </p>
    </form>
  );
};