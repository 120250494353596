import { supabase } from "@/integrations/supabase/client";

export const checkEmailExists = async (email: string): Promise<boolean> => {
  try {
    // First check in auth.users table through profiles
    const { data: profiles, error: profilesError } = await supabase
      .from('profiles')
      .select('email')
      .eq('email', email);

    if (profilesError) {
      console.error("Error checking email in profiles:", profilesError);
      throw profilesError;
    }

    // If we found any profiles with this email, it exists
    return profiles && profiles.length > 0;
  } catch (error) {
    console.error("Error checking email:", error);
    throw error;
  }
};