import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CheckCircle, ChevronRight, Star } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const Pricing = () => {
  const navigate = useNavigate();

  return (
    <section id="pricing" className="py-20 px-4 bg-[#DBAE7B]/10">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12 text-[#333333]">Our Local SEO Plans</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <Card className="hover:shadow-lg transition-shadow border-[#DBAE7B]/20">
            <CardHeader>
              <div className="flex items-center gap-2 mb-2">
                <Star className="text-[#e0933c]" />
                <CardTitle className="text-[#333333]">Maps SEO</CardTitle>
              </div>
              <CardDescription>
                <span className="text-2xl font-bold text-[#e0933c]">$598</span>/month
              </CardDescription>
              <p className="text-sm text-[#333333]/80 mt-2">
                Get discovered by customers near you with a laser focus on Google Maps.
              </p>
            </CardHeader>
            <CardContent>
              <ul className="space-y-3 mb-6">
                {[
                  "Google Maps Optimization",
                  "Local Citations Management",
                  "5-Mile Radius Optimization",
                ].map((feature) => (
                  <li key={feature} className="flex items-center gap-2">
                    <CheckCircle className="text-[#e0933c] h-5 w-5" />
                    <span className="text-[#333333]">{feature}</span>
                  </li>
                ))}
              </ul>
              <p className="text-sm text-[#333333]/80 mb-6">
                Best for: Businesses looking to drive local foot traffic and capture nearby customers.
              </p>
              <Button 
                className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90"
                onClick={() => navigate('/signup/maps_seo')}
              >
                Get Started <ChevronRight />
              </Button>
            </CardContent>
          </Card>

          <Card className="hover:shadow-lg transition-shadow border-[#e0933c]">
            <CardHeader>
              <div className="flex items-center gap-2 mb-2">
                <Star className="text-[#e0933c]" />
                <CardTitle className="text-[#333333]">Maps + Website SEO</CardTitle>
              </div>
              <CardDescription>
                <span className="text-2xl font-bold text-[#e0933c]">$998</span>/month
              </CardDescription>
              <p className="text-sm text-[#333333]/80 mt-2">
                Expand your visibility beyond Maps with a blend of local and website SEO strategies.
              </p>
            </CardHeader>
            <CardContent>
              <ul className="space-y-3 mb-6">
                {[
                  "Everything in Maps SEO",
                  "Basic Website Optimization",
                  "Technical SEO (1 page/month)",
                  "1 City Targeting",
                ].map((feature) => (
                  <li key={feature} className="flex items-center gap-2">
                    <CheckCircle className="text-[#e0933c] h-5 w-5" />
                    <span className="text-[#333333]">{feature}</span>
                  </li>
                ))}
              </ul>
              <p className="text-sm text-[#333333]/80 mb-6">
                Best for: Businesses wanting to capture both local and search engine traffic.
              </p>
              <Button 
                className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90"
                onClick={() => navigate('/signup/maps_website_seo')}
              >
                Get Started <ChevronRight />
              </Button>
            </CardContent>
          </Card>

          <Card className="hover:shadow-lg transition-shadow border-[#DBAE7B]/20">
            <CardHeader>
              <div className="flex items-center gap-2 mb-2">
                <Star className="text-[#e0933c]" />
                <CardTitle className="text-[#333333]">Custom SEO</CardTitle>
              </div>
              <CardDescription>
                <span className="text-2xl font-bold text-[#e0933c]">Prices vary</span>
              </CardDescription>
              <p className="text-sm text-[#333333]/80 mt-2">
                Customized solutions for businesses in competitive industries or with unique needs.
              </p>
            </CardHeader>
            <CardContent>
              <ul className="space-y-3 mb-6">
                {[
                  "SEO for Competitive Verticals",
                  "Multi-City Targeting",
                  "Fully customized strategies",
                  "Monthly Strategy Calls",
                ].map((feature) => (
                  <li key={feature} className="flex items-center gap-2">
                    <CheckCircle className="text-[#e0933c] h-5 w-5" />
                    <span className="text-[#333333]">{feature}</span>
                  </li>
                ))}
              </ul>
              <p className="text-sm text-[#333333]/80 mb-6">
                Best for: Businesses targeting multiple locations or requiring advanced SEO strategies.
              </p>
              <Button 
                className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90"
                onClick={() => navigate('/contact')}
              >
                Contact Us <ChevronRight />
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </section>
  );
};