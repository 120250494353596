import { Circle, Check } from "lucide-react";
import { CombinedInfoStep } from "./steps/CombinedInfoStep";
import { CheckoutStep } from "./steps/CheckoutStep";
import { GoogleConnectStep } from "./steps/GoogleConnectStep";
import { WooboundLogo } from "@/components/shared/WooboundLogo";

interface SignupFlowStepsProps {
  step: number;
  personalInfo: any;
  businessInfo: any;
  onCombinedInfoComplete: (data: any) => void;
  onCheckoutComplete: () => void;
  onGoogleConnectComplete: (connected: boolean) => void;
}

const StepIndicator = ({ currentStep, totalSteps }: { currentStep: number; totalSteps: number }) => {
  return (
    <div className="flex items-center justify-between mb-8">
      {Array.from({ length: totalSteps }).map((_, index) => {
        const stepNumber = index + 1;
        const isCompleted = stepNumber < currentStep;
        const isCurrent = stepNumber === currentStep;

        return (
          <div key={stepNumber} className="flex items-center flex-1">
            <div className="flex items-center space-x-2">
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center shrink-0 ${
                  isCompleted
                    ? "bg-[#e0933c]"
                    : isCurrent
                    ? "bg-[#e0933c]/20 text-[#e0933c]"
                    : "bg-gray-200"
                }`}
              >
                {isCompleted ? (
                  <Check className="w-4 h-4 text-white" />
                ) : (
                  <span className="text-sm">{stepNumber}</span>
                )}
              </div>
              <span
                className={`text-xs whitespace-nowrap ${
                  isCurrent ? "text-[#e0933c]" : "text-gray-500"
                }`}
              >
                Step {stepNumber}
              </span>
            </div>
            {stepNumber < totalSteps && (
              <div
                className={`h-0.5 w-full mx-4 ${
                  stepNumber < currentStep ? "bg-[#e0933c]" : "bg-gray-200"
                }`}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export const SignupFlowSteps = ({
  step,
  personalInfo,
  businessInfo,
  onCombinedInfoComplete,
  onCheckoutComplete,
  onGoogleConnectComplete,
}: SignupFlowStepsProps) => {
  return (
    <div className="space-y-4">
      <WooboundLogo />
      <StepIndicator currentStep={step} totalSteps={3} />
      
      {step === 1 && (
        <CombinedInfoStep onComplete={onCombinedInfoComplete} />
      )}
      {step === 2 && personalInfo && businessInfo && (
        <CheckoutStep
          personalInfo={personalInfo}
          businessInfo={businessInfo}
          onComplete={onCheckoutComplete}
        />
      )}
      {step === 3 && (
        <GoogleConnectStep onComplete={onGoogleConnectComplete} />
      )}
    </div>
  );
};