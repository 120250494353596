import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { ResourceList } from "@/components/resources/ResourceList";
import { differenceInMonths } from "date-fns";
import { useToast } from "@/hooks/use-toast";
import { Card } from "@/components/ui/card";
import { useEffect } from "react";

declare global {
  interface Window {
    SB_ARTICLES_PAGE: boolean;
  }
}

const Resources = () => {
  const { t } = useTranslation();
  const { toast } = useToast();

  useEffect(() => {
    // Set the articles page flag
    window.SB_ARTICLES_PAGE = true;

    // Cleanup function
    return () => {
      delete window.SB_ARTICLES_PAGE;
    };
  }, []);

  const { data: articles, isLoading } = useQuery({
    queryKey: ['articles'],
    queryFn: async () => {
      // Get current user's session
      const { data: session } = await supabase.auth.getSession();
      if (!session.session?.user.id) {
        console.log('No active session found');
        return [];
      }

      // Get user's profile to check account creation date
      const { data: profile } = await supabase
        .from('profiles')
        .select('created_at')
        .eq('id', session.session.user.id)
        .single();

      if (!profile?.created_at) {
        console.log('No profile found');
        return [];
      }

      // Calculate account age in months
      const accountAgeMonths = differenceInMonths(
        new Date(),
        new Date(profile.created_at)
      );

      console.log('Account age in months:', accountAgeMonths);

      // Fetch relevant published articles based on account age
      const { data: articles, error } = await supabase
        .from('articles')
        .select(`
          *,
          article_views (
            completed,
            progress_percent,
            time_spent_seconds
          )
        `)
        .eq('status', 'published')
        .lte('min_account_age_months', accountAgeMonths)
        .or(`max_account_age_months.is.null,max_account_age_months.gte.${accountAgeMonths}`)
        .order('min_account_age_months', { ascending: true });

      if (error) {
        console.error('Error fetching articles:', error);
        toast({
          title: "Error",
          description: "Failed to load resources",
          variant: "destructive",
        });
        return [];
      }

      return articles || [];
    },
  });

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-gray-50">
        <AppSidebar />
        <div className="flex-1 p-8">
          <div className="max-w-6xl mx-auto space-y-8">
            <div className="mb-8">
              <h1 className="text-2xl font-semibold text-gray-900">{t('resources.title')}</h1>
              <p className="text-sm text-gray-500">{t('resources.subtitle')}</p>
            </div>

            <ResourceList articles={articles || []} isLoading={isLoading} />

            <div className="mt-12">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Articles</h2>
              <Card className="p-6">
                <div id="sb-articles" className="sb-loading"></div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default Resources;