import { Card } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { Badge } from "@/components/ui/badge";
import { Clock, BookOpen, Crown, CheckCircle } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Article {
  id: string;
  title: string;
  description: string;
  link: string;
  category: string;
  premium: boolean;
  read_time_minutes: number;
  tags: string[];
}

interface ResourceCardProps {
  article: Article;
  progress: number;
  completed: boolean;
}

export const ResourceCard = ({ article, progress, completed }: ResourceCardProps) => {
  const { t } = useTranslation();

  return (
    <Card className="group overflow-hidden transition-all hover:shadow-md">
      <div className="p-6">
        <div className="flex items-start justify-between mb-2">
          <h3 className="font-semibold text-gray-900">{article.title}</h3>
          {article.premium && (
            <Badge variant="secondary" className="bg-yellow-100 text-yellow-800">
              <Crown className="h-3 w-3 mr-1" />
              Premium
            </Badge>
          )}
        </div>
        
        <p className="text-sm text-gray-500 mb-4 line-clamp-2">{article.description}</p>
        
        <div className="flex items-center gap-4 text-sm text-gray-500 mb-4">
          <div className="flex items-center">
            <Clock className="h-4 w-4 mr-1" />
            {article.read_time_minutes} min
          </div>
          {completed && (
            <div className="flex items-center text-green-600">
              <CheckCircle className="h-4 w-4 mr-1" />
              Completed
            </div>
          )}
        </div>

        <div className="space-y-4">
          {!completed && (
            <Progress value={progress} className="h-2" />
          )}
          
          <Link
            to={`/articles/${article.id}`}
            className="inline-flex items-center gap-2 text-[#F97316] hover:text-orange-600 transition-colors text-sm font-medium group-hover:gap-3"
          >
            <BookOpen className="h-4 w-4" />
            {completed ? t('resources.readAgain') : t('resources.startReading')}
          </Link>
        </div>
      </div>
    </Card>
  );
};