import { BusinessWithProfile, CompletionStatus } from "./types";

export const getCompletionStatus = (business: BusinessWithProfile): CompletionStatus => {
  const requiredFields = [
    'business_name',
    'website_url',
    'street_address',
    'city',
    'state',
    'zip_code',
    'category',
    'phone_number',
    'opening_hours'
  ];
  
  const completed = requiredFields.filter(field => business[field]).length;
  const total = requiredFields.length;
  
  return {
    completed,
    total,
    fields: requiredFields.map(field => ({
      name: field,
      completed: Boolean(business[field])
    }))
  };
};

export const exportToCSV = (businesses: BusinessWithProfile[]) => {
  const csvRows = [];
  const headers = [
    'Business Name',
    'Website URL',
    'Street Address',
    'City',
    'State',
    'ZIP Code',
    'Category',
    'Phone Number',
    'Opening Hours'
  ];
  csvRows.push(headers.join(','));

  for (const business of businesses) {
    const values = [
      business.business_name,
      business.website_url,
      business.street_address,
      business.city,
      business.state,
      business.zip_code,
      business.category,
      business.phone_number,
      business.opening_hours
    ];
    csvRows.push(values.join(','));
  }

  const csvString = csvRows.join('\n');
  const blob = new Blob([csvString], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', 'business_profiles.csv');
  a.click();
  URL.revokeObjectURL(url);
};
