import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import { Video } from "lucide-react";

interface PartnerVideo {
  id: string;
  title: string;
  description: string | null;
  video_url: string;
}

export const PartnerVideos = () => {
  const { data: videos, isLoading } = useQuery({
    queryKey: ['partner_videos'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('partner_videos')
        .select('*');
      if (error) throw error;
      return data as PartnerVideo[];
    },
  });

  if (isLoading) {
    return (
      <section>
        <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
          <Video className="h-5 w-5 text-[#F97316]" />
          Sales Videos
        </h2>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {Array(3).fill(0).map((_, i) => (
            <Card key={i} className="p-6 animate-pulse">
              <div className="aspect-video bg-gray-200 mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
              <div className="h-3 bg-gray-200 rounded w-1/2"></div>
            </Card>
          ))}
        </div>
      </section>
    );
  }

  return (
    <section>
      <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
        <Video className="h-5 w-5 text-[#F97316]" />
        Sales Videos
      </h2>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {videos?.map((video) => (
          <Card key={video.id} className="overflow-hidden">
            <div className="aspect-video">
              <iframe
                src={video.video_url}
                title={video.title}
                className="w-full h-full"
                allowFullScreen
              />
            </div>
            <div className="p-4">
              <h3 className="font-semibold text-gray-900 mb-2">{video.title}</h3>
              <p className="text-sm text-gray-500">{video.description}</p>
            </div>
          </Card>
        ))}
      </div>
    </section>
  );
};